import {Component, Input} from '@angular/core';

@Component({
  selector: 'glom-progress',
  templateUrl: './progress.component.html',
  styleUrl: './progress.component.scss',
})
export class ProgressComponent {
  @Input() progress = 0;
}
