import {
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
export class TimeRangeValidators {
  static compareTime(): ValidatorFn {
    return (control: AbstractControl) => {
      let isValid = false;
      if (control?.value?.from && control?.value?.to && (control?.value?.to < control?.value?.from)) {
        isValid = false;
      } else {
        isValid = true;
      }
      return isValid ? null : {compareTimeErr: true};
    };
  }
}
