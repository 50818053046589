import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { NoteComponent } from './note.component';
@NgModule({
  declarations: [NoteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [NoteComponent],
})
export class NoteModule {}
