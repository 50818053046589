import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
// import {DialogBtnType, DialogService} from '@core/components';
// import {ACCESS_TOKEN, AME_REGION_COUNTRY_LIST, COUNTRY_ID, ID_TOKEN_PAYLOAD} from '@core/constants/common.const';
// import {IIdTokenDecode} from '@core/models/common.models';
// import {getCountryId} from '@core/utils';
// import {environments} from '@env/environments';
// import {APP_URL} from '@env/environments';
import {BehaviorSubject} from 'rxjs';
// import {v4 as uuidv4} from 'uuid';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  //   userInfo$ = new BehaviorSubject<IIdTokenDecode | null>(null);
  constructor() {
    // Todo function
  }

  /**
   * Method handler convert params api
   * @param apiUriTemplate api template
   * @param params data you want to add to params api
   * @return api have data
   */
  buildAPIUrl<T>(apiUriTemplate: string, params?: T): string {
    // Sample URL:
    // 'root/{projectId}/find-childs?vpId={vpId}|tmpId={tmpId}'

    // Filter query parameters
    let queryParams = null;
    if (params) {
      // tslint:disable-next-line:forin
      for (const key in params) {
        const chkKey = '/{' + key;
        if (apiUriTemplate.indexOf(chkKey) < 0) {
          if (!queryParams) {
            queryParams = {};
          }
          // @ts-ignore
          queryParams[key] = params[key];
        }
      }
    }

    // Rebuild query parameter of template
    const urlParts = apiUriTemplate.split('?');
    let newUrl = apiUriTemplate;
    if (urlParts.length > 1 && queryParams) {
      newUrl = urlParts[0] + '?' + this.buildQueryParamsString(queryParams);
    }

    // Assign value of request parameters
    if (params) {
      // @ts-ignore
      Object.keys(params).forEach(
        // @ts-ignore
        key => (newUrl = newUrl.replace(`{${key}}`, params[key]))
      );
    }
    // const tokenPayload: IIdTokenDecode = JSON.parse(localStorage.getItem(ID_TOKEN_PAYLOAD) || '{}');
    // newUrl = newUrl.replace('{appUserId}', tokenPayload.sub);
    // let newIdBaseUrl = environments.idBaseUrl;
    const newIdBaseUrl = '';
    // const isAME = !!AME_REGION_COUNTRY_LIST.some(
    //   countryId => countryId.toUpperCase() === tokenPayload.country_id.toUpperCase()
    // );
    // if (isAME) {
    //   newIdBaseUrl = environments.idBaseUrl.replace('ao', 'ame');
    // }
    return newIdBaseUrl + newUrl;
  }

  /**
   * Method handler query params for api
   * @param body data query params
   */
  buildQueryParamsString(body: any): string {
    return Object.keys(body)
      .map(key => {
        const paramValue = body[key] || '';
        return encodeURIComponent(key) + '=' + encodeURIComponent(paramValue);
      })
      .join('&');
  }

  /**
   * Set header datas
   * @param headers
   */
  setHeaderDatas(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('x-event-time', new Date().toISOString());
    // headers = headers.append('x-one-time-uuid', uuidv4());
    // const countryId = getCountryId();
    headers = headers.append('x-country-id', 'countryId');
    return headers;
  }

  isOnline(): boolean {
    return navigator.onLine;
  }

  logout() {
    // Todo function
  }
}
