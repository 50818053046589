export interface IPackageData {
  hasHeader: boolean;
  hasfullText: boolean;
  packageId?: string;
  recipientName?: string;
  recipientAddressPref?: string;
  recipientAddressCity?: string;
  recipientAddressDetail?: string;
  deliverySpecifyDateFrom?: string | null;
  deliverySpecifyDateTo?: string | null;
  deliverySpecifyTimeFrom?: string | null;
  deliverySpecifyTimeTo?: string | null;
}

export interface IPackageDataDetail extends IPackageData {
  detailMode: boolean;
  recipientTele?: string;
  receiptQuantity?: number;
  receiptWeight?: number;
  receiptMemo?: string;
  imageUrls?: Array<string>;
}
