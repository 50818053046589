import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-bottom-sheet-common-layout',
  templateUrl: './bottom-sheet-common-layout.component.html',
  styleUrl: './bottom-sheet-common-layout.component.scss',
})
export class BottomSheetCommonLayoutComponent {
  @Input() title = '';
  @Input() isDisabledPositiveBtn = false;
  @Output() negativeEvent = new EventEmitter();
  @Output() positiveEvent = new EventEmitter();
  constructor() {}

  onNegativeButton(): void {
    this.negativeEvent.emit();
  }

  onPositiveButton(): void {
    this.positiveEvent.emit();
  }
}
