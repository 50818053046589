import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {CMS_CONST} from '@glom-cms/constants/cms.const';
import NoValuePipe from './no-value.pipe';
@Pipe({
  name: 'dateFromTo',
  standalone: true,
})
class DateFromToPipe implements PipeTransform {
  constructor(
    private readonly datePipe: DatePipe,
    private readonly noValue: NoValuePipe
  ) {}
  transform(from: any, to: any): any {
    const dateFrom = new Date(from);
    const dateTo = new Date(to);
    if (isNaN(dateFrom.getTime()) && isNaN(dateTo.getTime())) {
      return '指定なし';
    } else {
      const formatDateTimeFrom = this.datePipe.transform(
        from,
        CMS_CONST.dateFormat
      );
      const formatDateTimeTo = this.datePipe.transform(
        to,
        CMS_CONST.dateFormat
      );
      if (formatDateTimeFrom === formatDateTimeTo) {
        return formatDateTimeFrom || '指定なし';
      } else {
        if (!formatDateTimeFrom || !formatDateTimeTo) {
          const headContent = formatDateTimeFrom || formatDateTimeTo;
          const endContent = formatDateTimeFrom ? '以降' : 'まで';
          return headContent + endContent;
        } else {
          return `${formatDateTimeFrom} ~ ${formatDateTimeTo}`;
        }
      }
    }
  }
}

export default DateFromToPipe;
