import {Injectable} from '@angular/core';
import {
  ICompany,
  IReqCompany,
  IStatus,
  IStatusDisplay,
  ISubStatusDisplay,
} from './main.models';
import {BehaviorSubject, Observable, map} from 'rxjs';
import {CommonService} from '@glom-cms/services/common.service';
import {API} from '@glom-cms/constants/api.const';
import {HttpClient} from '@angular/common/http';
import {IPackage} from './package-management/shared/package.models';
import {IGNORE_LOADING_KEY} from '@glom-common/consts/index';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  companies$ = new BehaviorSubject<Array<ICompany>>([]);
  shipperCompanies$ = new BehaviorSubject<Array<ICompany>>([]);
  voucherCompanies$ = new BehaviorSubject<Array<ICompany>>([]);
  status$ = new BehaviorSubject<Array<IStatus>>([]);
  packages$ = new BehaviorSubject<Array<IPackage>>([]);
  statusDisplay$ = new BehaviorSubject<Array<IStatusDisplay>>([]);
  subStatusDisplay$ = new BehaviorSubject<Array<ISubStatusDisplay>>([]);
  emailNameAddress$ = new BehaviorSubject<Array<string>>([]);
  constructor(
    private readonly commonService: CommonService,
    private readonly httpClient: HttpClient
  ) {}

  getListCompany(param: IReqCompany): Observable<any> {
    const api = this.commonService.buildAPIUrl(
      API.UserManagement.getCompanyList,
      param
    );
    return this.httpClient.get<any>(api);
  }

  getListDistrict(param: ICompany): Observable<any> {
    const api = this.commonService.buildAPIUrl(
      API.UserManagement.getDistrictList,
      param
    );
    return this.httpClient.get<any>(api);
  }

  getListProvince(param: ICompany): Observable<any> {
    const api = this.commonService.buildAPIUrl(
      API.UserManagement.getProvinceList,
      param
    );
    return this.httpClient.get<any>(api);
  }

  getStatus(
    companyId?: string,
    isSearchPackageForm?: boolean
  ): Observable<Array<IStatus>> {
    const api = this.commonService.buildAPIUrl(API.general.getStatus, {
      companyId,
    });
    return this.httpClient
      .get<any>(api, {
        headers: {
          [IGNORE_LOADING_KEY]: isSearchPackageForm ? 'true' : '',
        },
      })
      .pipe(map((res: any) => res.data)) as Observable<Array<IStatus>>;
  }

  getEmailNameAddress(): Observable<any> {
    const api = this.commonService.buildAPIUrl(
      API.emailSettings.getEmailNameAddress
    );
    return this.httpClient
      .get<any>(api)
      .pipe(map((res: any) => res.data)) as Observable<any>;
  }

  filterCompany(value: string, listCompany: ICompany[]) {
    return listCompany.filter(o => o.companyName.toLowerCase().includes(value));
  }

  displayCompanyFn = (companyId: string, companyList: ICompany[]) => {
    const company = companyList.find(item => item.companyId === companyId);
    return company ? company.companyName : '';
  };
}
