import {Component, Input} from '@angular/core';

@Component({
  selector: 'glom-fe-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent {
  @Input() textLoading = '';
  @Input() isWebApp: boolean = false;
  @Input() isWeb: boolean = false;
}
