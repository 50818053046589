import {HttpClient} from '@angular/common/http';
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import heic2any from 'heic2any';
import {TiffConversionService} from '../../services';

@Directive({
  selector: 'img[convertImage]',
  standalone: true,
})
export class ConvertImageDirective implements OnChanges {
  @Input('glomConvertImage') imageUrl: string | undefined;
  @Input('glomConvertImageFile') imageFile: File | undefined;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private http: HttpClient,
    private readonly tiffConversionService: TiffConversionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageUrl'] && this.imageUrl) {
      this.loadImage(this.imageUrl);
    }
    if (changes['imageFile'] && this.imageFile) {
      this.loadImageFile(this.imageFile);
    }
  }

  private async loadImage(imageUrl: string): Promise<void> {
    const extension = this.getFileExtension(imageUrl);    
    if (
      extension.toLowerCase() === 'heic' ||
      extension.toLowerCase() === 'heif'
    ) {
      this.http.get(imageUrl, {responseType: 'blob'}).subscribe(
        blob => {
          this.convertHeicAndDisplay(blob);
        },
        error => {
          console.log('Error fetching the image:', error);
        }
      );
    } else if (extension.toLowerCase() === 'tiff') {
      this.tiffConversionService
        .convertAndDisplayImage(imageUrl)
        .subscribe(jpgBase64Data => {
          this.el.nativeElement.src = jpgBase64Data;
        });
    } else {
      this.el.nativeElement.src = imageUrl;
    }
  }

  private getFileExtension(url: string): any {
    const fileName = url.split('/').pop()?.split('?')[0];
    return fileName?.split('.').pop();
  }

  private async convertHeicAndDisplay(heicBlob: Blob): Promise<void> {
    try {
      const imageBlob = await heic2any({
        blob: heicBlob,
        toType: 'image/jpeg',
        quality: 0.4,
      });
      const finalBlob = Array.isArray(imageBlob) ? imageBlob[0] : imageBlob;
      const imageUrl = URL.createObjectURL(finalBlob);
      this.el.nativeElement.src = imageUrl;
    } catch (error) {
      console.log('Error converting HEIC to PNG:', error);
    }
  }

  private async loadImageFile(file: File): Promise<void> {
    const extension = this.getFileExtension(file.name);
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const base64Data = e.target.result;
      let imagePath: string | undefined;

      if (
        extension?.toLowerCase() === 'heic' ||
        extension?.toLowerCase() === 'heif'
      ) {
        const heicBlob = await fetch(base64Data).then(res => res.blob());
        const convertedBlob = await heic2any({
          blob: heicBlob,
          toType: 'image/jpeg',
          quality: 0.4,
        });
        imagePath = URL.createObjectURL(
          Array.isArray(convertedBlob) ? convertedBlob[0] : convertedBlob
        );
      } else if (
        extension?.toLowerCase() === 'tiff' ||
        extension?.toLowerCase() === 'tif'
      ) {
        imagePath = await this.tiffConversionService.convertTiffToJpg(
          base64Data
        );
      } else {
        imagePath = base64Data;
      }

      if (imagePath) {
        this.el.nativeElement.src = imagePath;
      }
    };

    reader.readAsDataURL(file); // Read the file as a data URL
  }
}
