import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Directive({
  selector: '[noneBreakLine]',
  standalone: true,
})
export class NoneBreakLineDirective {
  @Input() form!: FormGroup;
  @Input() field: string = '';  
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event'])
  onInput(event: any) {
    const currentValue: string = this.el.nativeElement.value;
    event.target.value = currentValue.replace(/[\r\n]/g, '');
    if (this.form) {
        this.form.controls[this.field].setValue(event.target.value);
    }
  }
}
