import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'glom-warning-card',
  templateUrl: './warning-card.component.html',
  styleUrl: './warning-card.component.scss',
})
export class WarningCardComponent {
  @Input() text: string = '';
}
