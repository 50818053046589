import {Directive, ElementRef, AfterViewInit} from '@angular/core';

@Directive({
  selector: 'img[responsiveImage]',
  standalone: true,
})
export class ResponsiveImageDirective implements AfterViewInit {
  constructor(private el: ElementRef<HTMLImageElement>) {}

  ngAfterViewInit() {
    this.checkWidths();
  }

  private checkWidths(): void {
    const childElement: HTMLImageElement = this.el.nativeElement;
    const parentElement = childElement.parentElement;

    const parentWidth = parentElement?.offsetWidth || -1;
    const childWidth = childElement.offsetWidth;
    const parentHeight = parentElement?.offsetHeight || -1;
    const childHeight = childElement.offsetHeight;

    if (childElement.naturalWidth > childElement.naturalHeight) {
      if (parentWidth < childWidth) {
        //case image width larger than container width
        childElement.style.maxWidth = '100%';
        childElement.style.maxHeight = 'auto';
      } else {
        //case normal
        childElement.style.maxWidth = '100%';
        childElement.style.maxHeight = '100%';
      }
    } else {
      if (parentHeight < childHeight) {
        //case image height larger than container height
        childElement.style.maxWidth = 'auto';
        childElement.style.maxHeight = '100%';
      } else {
        //case normal
        childElement.style.maxWidth = '100%';
        childElement.style.maxHeight = '100%';
      }
    }
  }
}
