import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {ToastMessageService} from './toast-message.service';
@NgModule({
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent],
  providers: [ToastMessageService],
  imports: [MatSnackBarModule, CommonModule],
})
export class ToastMessageModule {}
