import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PackageApiService } from '@glom-cms/apis/package.api.service';
import { Subject, takeUntil } from 'rxjs';
import { PARCEL_IMAGE_TYPE } from '@glom-common/consts/common.const';

@Component({
  selector: 'cms-package-image',
  templateUrl: './package-image.component.html',
  styleUrl: './package-image.component.scss',
})
export class PackageImageComponent implements OnInit, OnDestroy {
  imageUrl!: string;
  dataRouter!: string[];
  packageId: string = '';
  imagePosition!: string;
  type!: string;
  unSubscribe$ = new Subject();
  imgPaths: {name: string; imagePath: string}[] = [];
  imgPathsBill: {name: string; imagePath: string}[] = [];
  constructor(private readonly activatedRouter: ActivatedRoute, private readonly packageApiService: PackageApiService,) { }
  ngOnInit(): void {
    const params = { ...this.activatedRouter.snapshot.queryParams };
    this.dataRouter = params['data'].split('-');
    [this.packageId, this.imagePosition, this.type] = this.dataRouter;
    if (this.packageId) {
      this.handleGetPackageDetail(this.packageId);
    }
  }
  ngOnDestroy(): void {
    this.unSubscribe$.next(null);
  }
  handleGetPackageDetail(packageId: string) {
    this.packageApiService
      .getPackageDetail(packageId)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(res => {
        if (res) {
          const imagePathsParcel = res.data.parcelImages.filter((el:any)=>el.imageType !== PARCEL_IMAGE_TYPE.Bill);
          this.imgPathsBill = res.data.parcelImages.filter((el:any)=>el.imageType === PARCEL_IMAGE_TYPE.Bill).map((item:any)=>{
            return {
              name: (item.id||'').toString(),
              imagePath: item.imagePath,}
          });
          this.imgPaths = imagePathsParcel.map((img:any) => {
            return {
              imagePath: img.imagePath,
              name: '',
            };
          });
          if (Number(this.type)===PARCEL_IMAGE_TYPE.Bill) {
            this.imageUrl = this.imgPathsBill[Number(this.imagePosition)].imagePath;
          }else{
            this.imageUrl = this.imgPaths[Number(this.imagePosition)].imagePath;
          }
          console.log(this.imageUrl);
        }
        
      });
  }
}
