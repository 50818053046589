import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountInfoComponent} from './account-info.component';
import {ShareMaterialModule} from '@glom-fe/shared-ui';
import {TranslateModule} from '@ngx-translate/core';
import {ClickOutside} from '@glom-fe/shared-ui';

@NgModule({
  imports: [CommonModule, ShareMaterialModule, TranslateModule, ClickOutside],
  exports: [AccountInfoComponent],
  declarations: [AccountInfoComponent],
})
export class AccountInfoModule {}
