import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IPackageData} from '../card.model';

@Component({
  selector: 'app-luggage-card',
  templateUrl: './luggage-card.component.html',
  styleUrls: ['./luggage-card.component.scss'],
})
export class LuggageCardComponent {
  @Output() onClickEvent = new EventEmitter<any>();
  @Input() packageData: any;
  @Input() isOnePackage: any;

  handleClick(data: any) {
    this.onClickEvent.emit(data);
  }
}

// Data Input Guide !!!!

/** isOnepackage is used to distinguish "1 package case" from "many packages case" on "Package Register Screen" (check design and html file to see detail):
In "1 package" case: isOnepackage = true
In "many packages" case: isOnepackage = false 
In other screen like "package detail" of "package list": isOnepackage = true
*/

/** sampleData1 is used on Package List Screen:

sampleData1 = {
  hasHeader: true,
  hasfullText: true,
  packageId: "0123456789",
  recipientName: "NghiaDT15",
  recipientAddressPref: "F-Ville 3",
  recipientAddressCity: "Ha Noi",
  recipientAddressDetail: "Viet nam",
  deliverySpecifyDateFrom: null,
  deliverySpecifyDateTo: null,
  deliverySpecifyTimeFrom: "12:00",
  deliverySpecifyTimeTo: "13:00",
}
*/

/** sampleData2 is used on scan/manual register package screen

sampleData2 = {
  packageId: "0123456789",
  recipientName: "NghiaDT15",
  recipientAddressPref: "F-Ville 3",
  recipientAddressCity: "Ha Noi",
  recipientAddressDetail: "Viet nam",
  deliverySpecifyDateFrom: null,
  deliverySpecifyDateTo: null,
  deliverySpecifyTimeFrom: "12:00",
  deliverySpecifyTimeTo: "13:00",
}
*/

/** sampleData3, sampleData4, sampleData5 are used on Package Detail Screen: */

/** sampleData3 used to show information card of package (check design or run app to see detail)
sampleData3 = { 
  detailMode: true, 
  hasfullText: true,
  packageId: "0123456789",
  recipientName: "NghiaDT15",
  recipientAddressPref: "F-Ville 3",
  recipientAddressCity: "Ha Noi",
  recipientAddressDetail: "Viet nam",
  deliverySpecifyDateFrom: null,
  deliverySpecifyDateTo: null,
  deliverySpecifyTimeFrom: "12:00",
  deliverySpecifyTimeTo: "13:00",
  recipientTele: "0942019184",
  receiptQuantity: 1,
  receiptWeight: 10,
}
*/

/** sampleData4 used to show memp card of package (check design or run app to see detail)
sampleData4 = {
  receiptMemo: "Memo content",
}
*/

/** sampleData5 used to show image card of package (check design or run app to see detail)
sampleData5 = {
  imageUrls: [
    "sampleLinkUrl1",
    "sampleLinkUrl2"
  ],
}
*/
