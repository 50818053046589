import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  ACCESS_TOKEN,
  USERCODE,
  USERNAME,
} from '@glom-common/consts/common.const';
import {environments as ENV} from '@glom-cms/env/environment';
import {BehaviorSubject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import * as moment from 'moment-timezone';
import {v4 as uuidv4} from 'uuid';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  userInfo$ = new BehaviorSubject<any>(null);
  // queueExports = new BehaviorSubject<>();
  constructor() {
    const userName = localStorage.getItem(USERNAME);
    const userCode = localStorage.getItem(USERCODE);
    this.userInfo$.next({userCode, userName});
  }

  /**
   * Method handler convert params api
   * @param apiUriTemplate api template
   * @param params data you want to add to params api
   * @return api have data
   */
  buildAPIUrl<T>(apiUriTemplate: string, params?: T): string {
    // Sample URL:
    // 'root/{projectId}/find-childs?vpId={vpId}|tmpId={tmpId}'

    // Filter query parameters
    let queryParams = null;
    if (params) {
      // tslint:disable-next-line:forin
      for (const key in params) {
        const chkKey = '/{' + key;
        if (apiUriTemplate.indexOf(chkKey) < 0) {
          if (!queryParams) {
            queryParams = {};
          }
          // @ts-ignore
          queryParams[key] = params[key];
        }
      }
    }

    // Rebuild query parameter of template
    const urlParts = apiUriTemplate.split('?');
    let newUrl = apiUriTemplate;
    if (urlParts.length > 1 && queryParams) {
      newUrl = urlParts[0] + '?' + this.buildQueryParamsString(queryParams);
    }

    // Assign value of request parameters
    if (params) {
      // @ts-ignore
      Object.keys(params).forEach(
        // @ts-ignore
        key => (newUrl = newUrl.replace(`{${key}}`, params[key]))
      );
    }
    return ENV.BASE_URL + newUrl;
  }

  /**
   * Method handler query params for api
   * @param body data query params
   */
  buildQueryParamsString(body: any): string {
    return Object.keys(body)
      .map(key => {
        const paramValue = body[key] || '';
        return encodeURIComponent(key) + '=' + encodeURIComponent(paramValue);
      })
      .join('&');
  }

  /**
   * Set header datas
   * @param headers
   */
  setHeaderDatas(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('X-Event-Time', new Date().toISOString());
    headers = headers.append('X-One-Time-Uuid', uuidv4());
    return headers;
  }

  isOnline(): boolean {
    return navigator.onLine;
  }

  validateByErrObj(
    formGroup: FormGroup,
    msgErr: {[key: string]: string},
    validationMessages: any
  ): {[key: string]: string} {
    if (!formGroup) {
      return msgErr;
    }
    const form = formGroup;
    for (const field in msgErr) {
      (msgErr as {[key: string]: string})[field] = '';
      const control = form.get(field);
      if (control && control.invalid) {
        const messages = (
          validationMessages as {[key: string]: {[key: string]: string}}
        )[field];
        for (const key in control.errors) {
          (msgErr as {[key: string]: string})[field] += messages[key] + ' ';
        }
      }
    }
    return msgErr;
  }

  getCurrentTz(): string {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment().tz(currentTimeZone).format('Z');
  }

  logout() {
    // Todo function
  }
}
