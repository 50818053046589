import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
  Renderer2,
} from '@angular/core';
// import {STATUS_KEY, STATUS_TYPE} from './package-status/package-status.const';
import {STATUS, STATUS_COLOR_CONFIG} from '../constants';
@Directive({
  selector: '[statusBackgroundColor]',
  standalone: true,
})
export class StatusBackgroundColorDirective implements OnInit {
  @Input('statusId') statusId!: string;
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    const resultFind =
      STATUS_COLOR_CONFIG.find(
        status => status.key.toLowerCase() === this.statusId
      )?.backgroundColor || '';
    if (resultFind) {
      this.renderer.setStyle(
        this.el.nativeElement,
        'backgroundColor',
        resultFind
      );
    }
  }
}
