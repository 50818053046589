import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {Subject} from 'rxjs';
import { TimeService } from '../select-time-common/time.service';
import { DateRange, DefaultMatCalendarRangeStrategy, MatCalendar, MatRangeDateSelectionModel } from '@angular/material/datepicker';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrl: './select-date.component.scss',
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
})
export class SelectDateComponent implements OnInit, OnDestroy {
  unSubscribe$ = new Subject();
  @ViewChild('calendar') calendar: MatCalendar<Date> | undefined;
  selectedDateRange: DateRange<Date> | undefined;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {timeOptions: Array<string>; cb?: () => void, timeToSelect?: string; timeFromSelect?: string},
    private readonly bottomSheetRef: MatBottomSheetRef<SelectDateComponent>,
    private fb: FormBuilder,
    private readonly timeService: TimeService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
  ) {}

  
  ngOnInit(): void {
    const historyData = this.timeService.datePicker$.getValue();
    if (historyData) {
     this.rangeChanged(historyData.from || null);
     this.rangeChanged(historyData.to || null);
    }
  }

  // Event handler for when the date range selection changes.
  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );
    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );
  }

  onNegativeEvent(): void {
    this.bottomSheetRef.dismiss();
  }

  onPositiveEvent(): void {
    if (this.data.cb) {
      this.timeService.datePicker$.next({from: this.selectedDateRange?.start, to: this.selectedDateRange?.end});
      this.data.cb();
    }
    this.bottomSheetRef.dismiss();
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(null);
  }


}
