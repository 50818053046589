import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {Subject} from 'rxjs';
import {DatePipe} from '@angular/common';
import {ViewportScroller} from "@angular/common";
import { TimeService } from './time.service';

@Component({
  selector: 'app-select-time-common',
  templateUrl: './select-time-common.component.html',
  styleUrl: './select-time-common.component.scss',
})
export class SelectTimeCommonComponent implements OnInit, OnDestroy, AfterViewInit {
  unSubscribe$ = new Subject();
  timeFromOptions: Array<{id: string; value: string}> = [];
  timeToOptions: Array<{id: string; value: string}> = [];
  timePickerForm!: FormGroup;
  initTimeFrom:string | null | undefined;
  initTimeTo: string | null | undefined;
  @ViewChild("timeFrom") wrapperTimeFrom!: ElementRef;
  @ViewChild("timeTo") wrapperTimeTo!: ElementRef;
  
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {timeOptions: Array<string>; cb?: () => void, timeToSelect?: string; timeFromSelect?: string},
    private readonly bottomSheetRef: MatBottomSheetRef<SelectTimeCommonComponent>,
    private fb: FormBuilder,
    private readonly timeService: TimeService,
    private readonly datePipe: DatePipe,
    private readonly viewportScroller: ViewportScroller,
    private readonly ngZone: NgZone
  ) {}

  ngOnInit(): void {
    const historyData = this.timeService.timePicker$.getValue();
    if (!(historyData.timeFrom && historyData.timeTo)) {
      const timeNow = new Date();
      const {roundedDate, next30mRoundDate} = this.roundUpToNearest30Minutes(timeNow);
      this.initTimeFrom = this.datePipe.transform(roundedDate, "HH:mm");
      this.initTimeTo = this.datePipe.transform(next30mRoundDate, "HH:mm");
    } else {
      this.initTimeFrom = historyData.timeFrom;
      this.initTimeTo = historyData.timeTo;
    }
    // TODO remove time to, time from default
    this.timeFromOptions = this.data.timeOptions.map(val => {
      return {
        id: `time-from-${val}`,
        value: val
      }
    });
    this.timeToOptions = this.data.timeOptions.map(val => {
      return {
        id: `time-to-${val}`,
        value: val
      }
    });
    this.timePickerForm = this.fb.group({
      timeFrom: new FormControl(this.initTimeFrom),
      timeTo: new FormControl(this.initTimeTo),
    });
  }

  ngAfterViewInit(): void {
    const timeFormEle = document.getElementById(`time-from-${this.initTimeFrom}`);
    const timeToEle = document.getElementById(`time-to-${this.initTimeTo}`);
    timeFormEle?.scrollIntoView({ block: 'center'});
    timeToEle?.scrollIntoView({ block: 'center'});
  }
  roundUpToNearest30Minutes(date: Date): {
    roundedDate: Date;
    next30mRoundDate: Date; 
  } {
    const roundedDate = new Date(date.getTime()); // Create a copy to avoid mutating the original date
    const hours = roundedDate.getHours();
    const minutes = roundedDate.getMinutes();

    if (hours === 23 && minutes > 30) {
      roundedDate.setHours(23, 59, 0, 0); // Set to 23:59:00.000
    } else {
      const roundedMinutes = Math.ceil(minutes / 30) * 30;
      roundedDate.setMinutes(roundedMinutes);
      roundedDate.setSeconds(0);
      roundedDate.setMilliseconds(0);

      if (roundedMinutes === 60) {
        roundedDate.setHours(hours + 1);
        roundedDate.setMinutes(0);
      }
    }
    const next30mRoundDate = new Date(roundedDate.getTime());
    if (
      next30mRoundDate.getHours() === 23 &&
      next30mRoundDate.getMinutes() >= 30
    ) {
      next30mRoundDate.setHours(23, 59, 0, 0);
    } else {
      next30mRoundDate.setMinutes(next30mRoundDate.getMinutes() + 30);
    }
    return {roundedDate, next30mRoundDate};
  }

  onNegativeEvent(): void {
    this.bottomSheetRef.dismiss();
  }

  onPositiveEvent(): void {
    if (this.data.cb) {
      const {timeFrom, timeTo} = this.timePickerForm.value
      if(timeFrom < timeTo) {
        this.timeService.timePicker$.next(this.timePickerForm.value);
      } else {
        this.timeService.timePicker$.next({timeFrom: timeTo, timeTo: timeFrom});
      }
      this.data.cb();
    }
    this.bottomSheetRef.dismiss();
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(null);
  }
}
