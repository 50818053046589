import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrl: './note.component.scss',
})
export class NoteComponent {
  @Input() title =
    'お客様のお手元まで確実にお届けするため、お届け時のご要望と注意事項をお知らせください。';
  @Input() memoInfo: {name: string | null; address: string | null} = {
    name: null,
    address: null,
  };
}
