import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';
import {ITimeRange} from './time-range.models';
import {FormBuilder, FormGroup, FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

interface IValidationErrors {
  dateFromError: ValidationErrors | false;
  dateToError: ValidationErrors | false;
}

@Component({
  selector: 'glom-time-range',
  templateUrl: './time-range.component.html',
  styleUrl: './time-range.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimeRangeComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TimeRangeComponent,
    },
  ],
})
export class TimeRangeComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator, OnChanges
{
  unsubscribe$ = new Subject();
  @Output() eventDirty: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventErr: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() countSubmit = 0;
  @Input() countReset = 0;
  @Input() isRequired = false;
  @Input() timeRangeForm!: FormGroup;
  isShowErrorRangeTime: boolean = false;
  @ViewChild('rangeTimeElement') rangeTimeElement!: ElementRef;

  private _onChange = (obj: string) => {};
  private _onValidationChange: any = () => {};
  constructor(private readonly fb: FormBuilder, private translateService: TranslateService, public readonly cdr: ChangeDetectorRef) {
    // TODO: Something
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countSubmit']?.currentValue > 0) {
      this.timeRangeForm.markAllAsTouched();
      this._onValidationChange();
    }
    if (changes['countReset']?.currentValue > 0) {
      this.eventDirty.emit(this.timeRangeForm.dirty);
      this.timeRangeForm.reset();
    }
  }

  ngOnInit(): void {
    if (this.isRequired) {
      this.timeRangeForm = this.fb.group({
        from: new FormControl('', Validators.required),
        to: new FormControl('', Validators.required),
        timeHidden: new FormControl(''),
      });
    } else {
      this.timeRangeForm = this.fb.group({
        from: new FormControl(''),
        to: new FormControl(''),
        timeHidden: new FormControl(''),
      });
    }
    this.timeRangeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(values => {
        this._onChange(values);
        this._onValidationChange();
      });
  }
  writeValue(value: ITimeRange | null): void {
    if (this.timeRangeForm && value?.from) {
      this.timeRangeForm.patchValue({
        from: value?.from || null,
      });
      this._onChange(value as any);
    } else {
      this.timeRangeForm.patchValue({
        from: null,
      });
      this._onChange(value as any);
    }
    if (this.timeRangeForm && value?.to) {
      this.timeRangeForm.patchValue({
        to: value?.to || null,
      });
      this._onChange(value as any);
    } else {
      this.timeRangeForm.patchValue({
        to: null,
      });
      this._onChange(value as any);
    }
    // this._onValidationChange();
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
    this._onValidationChange();
  }
  registerOnTouched(fn: any): void {
    // this._onChange = fn;
    this._onValidationChange();
  }
  setDisabledState(isDisabled: boolean): void {
    // Todo function
  }

  onBlurEvent(event: any): void {
    this._onValidationChange();
  }

  validate(): IValidationErrors | null {
    const formErorr: IValidationErrors = {
      dateFromError: false,
      dateToError: false,
    };
    if (this.timeRangeForm.controls['from'].status === 'INVALID') {
      formErorr.dateFromError =
        this.timeRangeForm.controls['from'].errors || false;
    }
    if (this.timeRangeForm.controls['to'].status === 'INVALID') {
      formErorr.dateToError = this.timeRangeForm.controls['to'].errors || false;
    }
    if (formErorr.dateFromError || formErorr.dateToError) {
      return formErorr;
    }
    return null;
  }

  /**
   * Add registerOnValidatorChange to call it after every changes
   * @param fn
   */
  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  onChangeSendingTime(): void {
    const fromTime = this.timeRangeForm.get('from')?.value;
    const toTime = this.timeRangeForm.get('to')?.value;
    // if (fromTime && !toTime) {
    //   this.timeRangeForm.controls['timeHidden'].setErrors({
    //     validTime: this.translateService.instant('CM_INL_0016', {
    //       fieldName: this.translateService.instant('SW_1402-1434.30.LB-33'),
    //     }),
    //   });
    //   this.timeRangeForm.controls['to'].setErrors({
    //     required: true,
    //   });
    //   this.timeRangeForm.controls['from'].setErrors(null);
    //   return;
    // }

    // if (!fromTime && toTime) {
    //   this.timeRangeForm.controls['timeHidden'].setErrors({
    //     validTime: this.translateService.instant('CM_INL_0016', {
    //       fieldName: this.translateService.instant('SW_1402-1434.30.LB-34'),
    //     }),
    //   });
    //   this.timeRangeForm.controls['from'].setErrors({
    //     required: true,
    //   });
    //   this.timeRangeForm.controls['to'].setErrors(null);
    //   return;
    // }
    if (toTime && fromTime && toTime < fromTime) {
      this.timeRangeForm.controls['timeHidden'].setErrors({
        validTime: this.translateService.instant('CM_INL_0015'),
      });
      // this.timeRangeForm.controls['to'].setErrors({
      //   required: true,
      // });
      this.isShowErrorRangeTime = true;
      this.eventErr.emit(this.isShowErrorRangeTime);
      return;
    } else {
      this.isShowErrorRangeTime = false;
      this.eventErr.emit(this.isShowErrorRangeTime);
    }

    this.timeRangeForm.controls['timeHidden'].setErrors(null);
    this.timeRangeForm.controls['from'].setErrors(null);
    this.timeRangeForm.controls['to'].setErrors(null);
    console.log('change');
    
  }

  onUpdateSendingTime() {
    console.log('emit');
    setTimeout(() => {
      const parent = this.rangeTimeElement.nativeElement;
      const invalidElements = parent.querySelectorAll(
        '.mdc-text-field--invalid'
      );
      if (invalidElements.length) {
        this.isShowErrorRangeTime = true;
        this.eventErr.emit(this.isShowErrorRangeTime);
        this.onChangeSendingTime();
        this.cdr.detectChanges();
      } else {
        this.isShowErrorRangeTime = false;
        this.eventErr.emit(this.isShowErrorRangeTime);
        this.onChangeSendingTime();
        this.cdr.detectChanges();
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
  }
}
