import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {Subject, takeUntil} from 'rxjs';
import {IDaterange, IDateRangePlaceholder} from './date-range.models';
import {FormBuilder, FormGroup, FormControl} from '@angular/forms';

interface IValidationErrors {
  dateFromError: ValidationErrors | false;
  dateToError: ValidationErrors | false;
}

@Component({
  selector: 'glom-date-range',
  templateUrl: './date-range.component.html',
  styleUrl: './date-range.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangeComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DateRangeComponent,
    },
  ],
})
export class DateRangeComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator, OnChanges
{
  unsubscribe$ = new Subject();
  @Output() eventDirty: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitHasChange: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Input() dateRangePlaceholder: IDateRangePlaceholder = {from: 'From', to: 'To'};
  @Input() minDateTo = '';
  @Input() minDateFrom = '';
  @Input() countSubmit = 0;
  @Input() countReset = 0;
  @Input() isRequired = false;
  dateRangeForm!: FormGroup;

  private _onChange = (obj: string) => {};
  private _onValidationChange: any = () => {};
  constructor(private readonly fb: FormBuilder) {
    // TODO: Something
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countSubmit']?.currentValue > 0) {
      this.dateRangeForm.markAllAsTouched();
      this._onValidationChange();
    }
    if (changes['countReset']?.currentValue > 0) {
      this.eventDirty.emit(this.dateRangeForm.dirty);
      this.dateRangeForm.reset();
    }
  }

  ngOnInit(): void {
    if (this.isRequired) {
      this.dateRangeForm = this.fb.group({
        from: new FormControl('', Validators.required),
        to: new FormControl('', Validators.required),
      });
    } else {
      this.dateRangeForm = this.fb.group({
        from: new FormControl(''),
        to: new FormControl(''),
      });
    }
    this.dateRangeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(values => {
        this._onChange(values);
        this._onValidationChange();
      });
  }
  writeValue(value: IDaterange | null): void {
    console.log(value);
    if (this.dateRangeForm && value?.from) {
      this.dateRangeForm.patchValue({
        from: new Date(value?.from as any) || null,
      });
      this._onChange(value as any);
    } else {
      this.dateRangeForm.patchValue({
        from: null,
      });
      this._onChange(value as any);
    }
    if (this.dateRangeForm && value?.to) {
      this.dateRangeForm.patchValue({
        to: new Date(value?.to as any) || null,
      });
      this._onChange(value as any);
    } else {
      this.dateRangeForm.patchValue({
        to: null,
      });
      this._onChange(value as any);
    }
    // this._onValidationChange();
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
    this._onValidationChange();
  }
  registerOnTouched(fn: any): void {
    // this._onChange = fn;
    this._onValidationChange();
  }
  setDisabledState(isDisabled: boolean): void {
    // Todo function
  }

  onBlurEvent(event: any): void {
    this._onValidationChange();
    this.emitHasChange.emit(true);
  }

  onChange() {
    console.log('change');
    this.emitHasChange.emit(true);
  }

  validate(): IValidationErrors | null {
    this.dateRangeForm;
    const formErorr: IValidationErrors = {
      dateFromError: false,
      dateToError: false,
    };
    if (this.dateRangeForm.controls['from'].status === 'INVALID') {
      formErorr.dateFromError =
        this.dateRangeForm.controls['from'].errors || false;
    }
    if (this.dateRangeForm.controls['to'].status === 'INVALID') {
      formErorr.dateToError = this.dateRangeForm.controls['to'].errors || false;
    }
    if (formErorr.dateFromError || formErorr.dateToError) {
      return formErorr;
    }
    return null;
  }

  /**
   * Add registerOnValidatorChange to call it after every changes
   * @param fn
   */
  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
  }
}
