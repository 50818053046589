import {NgModule} from '@angular/core';
import {LuggageCardComponent} from './luggage-card/luggage-card.component';
import {CommonModule} from '@angular/common';
import {WarningCardComponent} from './warning-card/warning-card.component';
import {SlipNumberCardComponent} from './slip-number-card/slip-number-card.component';
import {TruncatePipe} from '../../pipes';
import DateFromToPipe from '../../pipes/date-fromto.pipe';
import TimeFromToPipe from '../../pipes/time-fromto.pipe';
import {LuggageCardHeaderComponent} from './luggage-card-header/luggage-card-header.component';
import NoValuePipe from '../../pipes/no-value.pipe';
@NgModule({
  imports: [CommonModule, TruncatePipe, DateFromToPipe, TimeFromToPipe, NoValuePipe],
  exports: [
    LuggageCardComponent,
    WarningCardComponent,
    SlipNumberCardComponent,
    LuggageCardHeaderComponent,
  ],
  declarations: [
    LuggageCardComponent,
    WarningCardComponent,
    SlipNumberCardComponent,
    LuggageCardHeaderComponent,
  ],
  providers: [],
})
export class CardModule {}
