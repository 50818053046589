import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class TimeService {
  timePicker$ = new BehaviorSubject<any>({
    timeFrom: '',
    timeTo: '',
  });
  datePicker$ = new BehaviorSubject<any>({
    from: '',
    to: '',
  });
  constructor() {}
}
