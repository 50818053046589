<div
  class="user-info"
  glomFeClickOutSide
  (clickOutSideEvent)="handleClickOutSide()">
  <div class="wrap-content d-flex" (click)="handleClick($event)">
    <img class="user-avatar mr-8" src="assets/icons/ic_avatar.svg" alt="" />
    <div class="wrap-name mr-8 d-flex flex-column">
      <span class="username">{{ userData.userName }}</span>
      <span class="usercode">{{ userData.userCode }}</span>
    </div>
    <div class="icon-down" [ngClass]="{'icon-down-rotate': isActive}">
      <img src="assets/icons/ic_arrow-down.svg" alt="" />
    </div>
  </div>
  <div class="logout" [ngClass]="{'show-logout': isActive}" (click)="logout()">
    <img src="assets/icons/ic_logout.svg" alt="" />
    <span class="menu-item--name">
      {{ 'SW_0200.BTN.LOGOUT' | translate }}
    </span>
  </div>
</div>
