import {updateOn} from '@rxweb/reactive-form-validators';

export const API = {
  signIn: 'id/auth/login',
  signOut: 'id/cim/user/logout',
  refreshToken: 'id/auth/refresh-token',
  UserManagement: {
    createAdmin: 'id/cim/admin',
    createShipper: 'id/cim/shipper',
    getAdminList: 'id/cim/admin/filter',
    getShipperList: 'id/cim/shipper/filter',
    getCompanyList:
      'id/lms/company/filter?id={id}&name={name}&companyType={companyType}&isLomHidden={isLomHidden}',
    getDistrictList: 'id/lms/district/filter',
    getProvinceList: 'id/lms/province/filter',
    updateAdmin: 'id/cim/admin/{userId}',
    updateShipper: 'id/cim/shipper/{userId}',
    deleteAdmin: 'id/cim/admin',
    deleteShipper: 'id/cim/shipper',
    searchShipper: 'id/cim/shipper?userCode={userCode}',
    searchCompanies: 'id/lms/company/filter',
    deleteCompany: 'id/lms/admin/company',
    updateCompany: 'id/lms/admin/company',
    createCompany: 'id/lms/admin/company',
    detailCompany: 'id/lms/admin/company/detail',
  },
  voucherIssuer: {
    createNew: 'id/lms/admin/issuer-company/registration',
    search: 'id/lms/admin/issuer-company/filter',
    detail: 'id/lms/admin/issuer-company/detail',
    update: 'id/lms/admin/issuer-company',
    checkBusinessType: 'id/lms/admin/business-type',
    delete: 'id/lms/admin/issuer-company',
    checkCategory: 'id/lms/admin/category',
  },
  package: {
    searchParcel: 'pm/pim/admin/parcel/filter',
    getPackageDetail: 'pm/pim/admin/parcel/{packageId}',
    packageHistory: 'pm/pim/admin/parcel/{packageId}/status',
    updateMemo: 'pm/pim/admin/parcel/{packageId}/memo',
    importPackage: 'pm/pim/admin/parcel/import-csv',
    exportCsv: 'pm/pim/admin/parcel/export-csv',
    exportCSVStatus: 'pm/pim/admin/parcel/export-csv/status',
    getPresignedUrl: 'pm/pim/admin/parcel/{packageId}/image/presigned-url',
    updatePackageImage: 'pm/pim/admin/parcel/{packageId}/image',
  },
  general: {
    getStatus: 'pm/pim/status?companyId={companyId}&businessTypeId={businessTypeId}',
  },
  dailyWork: {
    searchParcelUnregistered: 'pm/pim/admin/parcel/un-register',
    checkDuplicateParcel: 'pm/pim/admin/parcel/duplicate-parcel',
    updateCompanyParcelUnregistered:
      'pm/pim/admin/parcel/{packageId}/change-voucher-issuer',
    searchErrorsList: 'pm/pim/admin/error-file/filter',
    getLinkDownload: 'pm/pim/admin/error-file/{id}',
    deleteParcel: 'pm/pim/admin/parcel',
  },
  message: {
    searchDistributionMsg: 'id/lms/admin/notification/filter',
    createDistributionMsg: 'id/lms/admin/notification/register',
    updateDistributionMsg: 'id/lms/admin/notification/{distributionMsgId}',
    deleteDistributionMsg: 'id/lms/admin/notification/{distributionMsgId}',
  },
  emailSettings: {
    getEmailNameAddress: 'pm/pim/admin/mail/address-name',
    search: 'pm/pim/admin/mail/filter',
    detail: 'pm/pim/admin/mail',
    delete: 'pm/pim/admin/mail',
    createNew: 'pm/pim/admin/mail/register',
    update: 'pm/pim/admin/mail',
  },
  packageNoAuthen: {
    getBusinessType: 'id/lms/end-user/issuer-company/detail',
    registerPackage: 'pm/sdc/end-user/parcel/registration',
  }
};

export const EXCEPT_AUTHORIZATION_URL = ['amazonaws.com'];
