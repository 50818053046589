import {Directive, ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[glomFeRequired]',
  standalone: true,
})
export class RequiredDirective implements OnInit {
  constructor(
    private readonly ele: ElementRef,
    private readonly renderer: Renderer2
  ) {}
  ngOnInit(): void {
    if (this.ele.nativeElement) {
      const spanElement = this.renderer.createElement('span');
      const spanText = this.renderer.createText('*');
      this.renderer.setStyle(spanElement, 'color', '#BA1A1A');
      this.renderer.appendChild(spanElement, spanText);
      this.renderer.appendChild(this.ele.nativeElement, spanElement);
    }
  }
}
