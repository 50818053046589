import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {CommonService} from '@glom-cms/services/common.service';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'cms-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit, OnDestroy {
  isActive = false;
  userData = {
    userName: '',
    userCode: '',
  };
  destroy$ = new Subject<boolean>();
  @Output() clickLogOut = new EventEmitter<boolean>();

  constructor(private readonly commonService: CommonService) {}
  ngOnInit() {
    this.commonService.userInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.userData.userName = res.userName;
          this.userData.userCode = res.userCode;
        }
      });
  }
  logout() {
    this.clickLogOut.emit(true);
  }

  handleClickOutSide() {
    this.isActive = false;
  }

  handleClick(event: Event) {
    event.stopPropagation();
    this.isActive = !this.isActive;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
