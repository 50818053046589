import {
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  FormGroup,
} from '@angular/forms';
import * as moment from 'moment';
export class DateRangeValidators {
  static compareDate(): ValidatorFn {
    return (control: AbstractControl) => {
      let isValid = false;
      if (control?.value?.from && control?.value?.to) {
        const isFormDateValid = moment(control.value.from).isValid();
        const isToDateValid = moment(control.value.to).isValid();
        if (isToDateValid && isFormDateValid) {
          const fromDate = moment(control.value.from).set({
            hour: 0,
            minute: 0,
            millisecond: 0,
          });
          const toDate = moment(control.value.to).set({
            hour: 23,
            minute: 59,
            millisecond: 59,
          });
          if (moment(fromDate).isBefore(toDate)) {
            isValid = true;
          }
        }
      } else {
        isValid = true;
      }
      return isValid ? null : {compareDateErr: true};
    };
  }
}
