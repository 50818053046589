import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import {BehaviorSubject, finalize, Observable, throwError} from 'rxjs';
// TODO implement loading
// import {LoadingService} from '@core/components/loading';
// import {
//   ACCESS_TOKEN,
//   COUNTRY_ID,
//   ERROR_CODE_WHILE_LIST,
//   GrantTypeAuth0,
//   ID_TOKEN_PAYLOAD,
//   IGNORE_ERROR_CODE,
//   REFRESH_TOKEN,
// } from '@constants/common.const';
// import {CommonService} from '@services/common.service';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
// import {IIdTokenDecode, IResponseError} from '@core/models/common.models';
// import {DialogService, DialogType} from '@core/components/dialog';
// import {AuthClientConfig, AuthService} from '@auth0/auth0-angular';
// import {environments} from '@env/environments';
// import jwt_decode from 'jwt-decode';
// import {APP_URL} from '@env/environments';
import {HttpBackend} from '@angular/common/http';
import {ACCESS_TOKEN, REFRESH_TOKEN} from './constants/common.const';
import {CommonService} from './common.service';
// import {auth0API} from '@core/constants/api.const';
@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  private readonly requests: Array<HttpRequest<any>> = [];
  private isRefreshing = false;
  private isOpeningErrPopup = false;
  private readonly tokenSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  constructor(
    // private readonly loadingService: LoadingService,
    private readonly commonService: CommonService,
    // private readonly dialogService: DialogService,
    // private readonly authService: AuthService,
    private readonly httpBackend: HttpBackend
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = sessionStorage.getItem(ACCESS_TOKEN);
    // Build http request header
    let headers;
    if (authToken) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      headers = headers.append('Authorization', 'Bearer ' + authToken);
      headers = this.commonService.setHeaderDatas(headers);
    }

    if (!this.commonService.isOnline()) {
      const error = new HttpErrorResponse({
        error: 'Internet connectivity error',
        status: 0,
      });
      throwError(() => error);
    }

    const cloneReq = request.clone({headers});
    const authReq = cloneReq;
    this.requests.push(authReq);
    return next.handle(cloneReq).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 403 && !err?.error?.errorCode) {
          return this.handle403Error(cloneReq, next);
        }
        // this.loadingService.handleAutoLoadingDisplay(true); // on mode auto show loading
        // this.showDialogResultFailure(err);
        return throwError(() => err);
      }),
      finalize(() => {
        const index = this.requests.indexOf(authReq);
        if (index >= 0) {
          this.requests.splice(index, 1);
        }
        // if (this.requests.length === 0 && this.loadingService.isAutoLoadingDisplay) {
        //   this.loadingService.handleLoadingDisplay(false);
        // }
      })
    );
  }

  private handleInOpenPopup(dialogRef: Observable<any>) {
    this.isOpeningErrPopup = true;
    dialogRef.subscribe(res => {
      this.isOpeningErrPopup = false;
    });
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.tokenSubject.next(null);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      return new HttpClient(this.httpBackend)
        .post('url', 'data', {headers})
        .pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            sessionStorage.setItem(ACCESS_TOKEN, token.access_token);
            //   const decodedIdToken: IIdTokenDecode = jwt_decode(token.id_token);
            //   this.commonService.userInfo$.next(decodedIdToken);
            //   localStorage.setItem(ID_TOKEN_PAYLOAD, JSON.stringify(decodedIdToken));
            this.tokenSubject.next(token.access_token);
            return next.handle(
              this.addNewTokenHeader(request, token.access_token)
            );
          }),
          catchError(() => {
            this.isRefreshing = false;
            sessionStorage.clear();
            localStorage.clear();
            //   this.authService.logout({logoutParams: {returnTo: APP_URL}}).subscribe();
            return throwError(() => null);
          })
        );
    }

    return this.tokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this.addNewTokenHeader(request, token)))
    );
  }

  private addNewTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}
