import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimeRangeComponent} from './time-range.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ShareMaterialModule } from '../../share-material.module';
@NgModule({
  declarations: [TimeRangeComponent],
  imports: [
    MatDatepickerModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ShareMaterialModule,
  ],
  exports: [TimeRangeComponent],
})
export class TimeRangeModule {}
