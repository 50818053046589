import {NgModule} from '@angular/core';
import {LuggageCardComponent} from './luggage-card/luggage-card.component';
import {CommonModule} from '@angular/common';
import {WarningCardComponent} from './warning-card/warning-card.component';
import {SlipNumberCardComponent} from './slip-number-card/slip-number-card.component';
import {TruncatePipe} from '../../pipes';
import DateFromToPipe from '../../pipes/date-fromto.pipe';
import TimeFromToPipe from '../../pipes/time-fromto.pipe';
import {LuggageCardHeaderComponent} from './luggage-card-header/luggage-card-header.component';
import NoValuePipe from '../../pipes/no-value.pipe';
import {SelectTimeCommonComponent} from './select-time-common/select-time-common.component';
import {TimeService} from './select-time-common/time.service';
import {ShareMaterialModule} from '../../share-material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {SelectDateComponent} from './select-date/select-date.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import { BottomSheetCommonLayoutComponent } from './bottom-sheet-common-layout/bottom-sheet-common-layout.component';
@NgModule({
  imports: [
    CommonModule,
    TruncatePipe,
    DateFromToPipe,
    TimeFromToPipe,
    NoValuePipe,
    ShareMaterialModule,
    ReactiveFormsModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatCardModule,
  ],
  exports: [
    LuggageCardComponent,
    WarningCardComponent,
    SlipNumberCardComponent,
    LuggageCardHeaderComponent,
    SelectTimeCommonComponent,
    BottomSheetCommonLayoutComponent,
    SelectDateComponent,
  ],
  declarations: [
    LuggageCardComponent,
    WarningCardComponent,
    SlipNumberCardComponent,
    LuggageCardHeaderComponent,
    SelectTimeCommonComponent,
    BottomSheetCommonLayoutComponent,
    SelectDateComponent,
  ],
  providers: [TimeService],
})
export class CardModule {}
