import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Pipe,
  PipeTransform,
  Renderer2,
} from '@angular/core';
// import {STATUS_KEY, STATUS_TYPE} from './package-status/package-status.const';
import {STATUS, STATUS_COLOR_CONFIG} from '../constants';
@Directive({
  selector: '[statusTextColor]',
  standalone: true,
})
export class StatusTextColorDirective implements OnInit {
  @Input('statusId') statusId!: string;
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    const resultFind =
      STATUS_COLOR_CONFIG.find(
        status => status.key.toLowerCase() === this.statusId
      )?.textColor || '';
    if (resultFind) {
      this.renderer.setStyle(this.el.nativeElement, 'color', resultFind);
    }
  }
}
