export interface IReqPaginator {
  page: number;
  size: number;
  sortKey: string;
  sortType: string;
}

export interface IResPaginator {
  total: number;
  page: number;
  totalPage: number;
}
