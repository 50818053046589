import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IPackageData} from '../card.model';

@Component({
  selector: 'app-luggage-card-header',
  templateUrl: './luggage-card-header.component.html',
  styleUrls: ['./luggage-card-header.component.scss'],
})
export class LuggageCardHeaderComponent {
  @Input() packageData: any;
}
