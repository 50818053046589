export const USERNAME = 'userName';
export const USERCODE = 'userCode';
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const LANGUAGE = 'language';
export const IGNORE_LOADING_KEY = 'ignore-loading';

export const STATUS_KEY = {
  BEFORE_PICKUP: 'before_pickup',
  DELIVERY_PICKED: 'delivery_picked',
  DELIVERY_COMPLETED: 'delivery_completed',
  DELIVERY_FAILED: 'delivery_failed',
  RETURNED_PACKAGE: 'returned_pack',
};
export enum UserType {
  admin = 1,
  shipper = 2,
}

export const PAGE_SIZE = 8;
export const SORT_KEY = 'createAt';
export const SORT_TYPE = 'DESC';

export const Paginator = {
  size: PAGE_SIZE,
  sortKey: SORT_KEY,
  sortType: SORT_TYPE,
};

export const STATUS = [
  {
    key: STATUS_KEY.BEFORE_PICKUP,
    name: '未持出',
  },
  {
    key: STATUS_KEY.DELIVERY_PICKED,
    name: '持出中',
  },
  {
    key: STATUS_KEY.DELIVERY_FAILED,
    name: '持戻',
  },
  {
    key: STATUS_KEY.DELIVERY_COMPLETED,
    name: '配達完了',
  },
  {
    key: STATUS_KEY.RETURNED_PACKAGE,
    name: '返品',
  },
];

export const STATUS_COLOR_CONFIG = [
  {
    key: STATUS_KEY.BEFORE_PICKUP,
    textColor: '#2e3338',
    backgroundColor: '#dfe5ec',
  },
  {
    key: STATUS_KEY.DELIVERY_PICKED,
    backgroundColor: '#d9edfc',
    textColor: '#1079c4',
  },
  {
    key: STATUS_KEY.DELIVERY_FAILED,
    textColor: '#f76408',
    backgroundColor: '#fee6d7',
  },
  {
    key: STATUS_KEY.DELIVERY_COMPLETED,
    textColor: '#2aaa6e',
    backgroundColor: '#def7eb',
  },
  {
    key: STATUS_KEY.RETURNED_PACKAGE,
    textColor: '#542f9d',
    backgroundColor: '#e7dff6',
  },
];

export const PARCEL_IMAGE_TYPE = {
  Signature: 1,
  Photo: 2,
  Bill: 3,
};

export const ACCEPT_IMAGE_LIST = ".jpg, .jpeg, .png, .tiff, .gif, .bmp, .heic, .heif";