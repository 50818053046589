<div class="server-success" *ngIf="severSuccessDescription">
  <img src="assets/icons/ic_checkmark-green.svg" alt="" srcset="" />
  <p class="group-message--message">
    <span style="font-size: 24px; font-weight: 700">{{
      severSuccessDescription
    }}</span
    >件　登録に成功しました
  </p>
  <img
    class="close-msg"
    (click)="closeMsg()"
    src="assets/icons/ic_close.svg"
    alt=""
    width="24" />
</div>
<div
  class="container"
  [class.file-exist]="files.length && !severErrorDescription"
  [class.file-error]="severErrorDescription || fileInvalidMsg"
  [class.file-opactity]="activeDrag"
  appDnd
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (fileDropped)="onFileDropped($event)">
  <div
    *ngIf="!files.length && !severErrorDescription"
    class="content d-flex align-items-center flex-column">
    <input
      id=""
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple
      (change)="fileBrowseHandler($event?.target)" />
    <div class="lbl-file-header fw-400 fs-18">
      ここにcsvファイルをドロップしてください。または
    </div>
    <label
      class="lbl-file-drop-red d-flex align-items-center justify-content-center"
      for="fileDropRef"
      >ファイルをアップロード</label
    >
    <div class="text-hint fw-400">ファイルサイズの上限は10MBです。</div>
  </div>
  <div class="files-list" *ngIf="files.length && !severErrorDescription">
    <div
      class="single-file d-flex justify-content-between align-items-center"
      [ngClass]="{'file-success': file?.progress}"
      *ngFor="let file of files; let i = index">
      <div class="file-icon">
        <img src="assets/icons/ic_file-upload.svg" alt="" />
      </div>
      <div class="info w100">
        <h4 class="name m-0 fw-400 fs-18">
          {{ file?.name }}
        </h4>
        <!-- <p class="size">
          {{ formatBytes(file?.size) }}
        </p> -->
        <span class="success d-flex align-items-center" *ngIf="file?.progress">
          <img
            width=""
            src="assets/icons/ic_checkmark-green.svg"
            alt=""
            srcset="" />
          {{ 'Success' }}
        </span>
        <!-- <glom-progress [progress]="file?.progress"></glom-progress> -->
      </div>

      <img
        style="cursor: pointer"
        (click)="deleteFile(i)"
        src="assets/icons/ic_cancel.svg"
        alt="" />
    </div>
    <button
      (click)="uploadFile()"
      class="w-100 btn-upload-files"
      color="accent"
      mat-flat-button>
      <img src="assets/icons/ic_arrow-up-file.svg" alt="" srcset="" />登録
    </button>
  </div>
  <div class="server-error" *ngIf="severErrorDescription && files.length">
    <img src="assets/icons/ic_err-record.svg" alt="" srcset="" />
    <p class="fw-400 fs-18">{{ severErrorDescription }}</p>
    <div class="d-flex w100 confirm-btn">
      <button
        (click)="handleBacktoUploadFile()"
        style="width: 195px; height: 48px"
        mat-flat-button
        color="accent">
        その他csvを選択
      </button>
      <button
        (click)="handleGetfileErr()"
        style="width: 228px; height: 48px"
        mat-flat-button
        color="primary">
        エラー詳細をダウンロード
      </button>
    </div>
  </div>
</div>
<div *ngIf="fileInvalidMsg" class="file-err-message d-flex align-items-start">
  <img src="assets/icons/ic_err-file.svg" alt="" srcset="" />
  <p class="fw-400">{{ fileInvalidMsg }}</p>
</div>
