<app-bottom-sheet-common-layout
  (positiveEvent)="onPositiveEvent()"
  (negativeEvent)="onNegativeEvent()"
  title="配達指定日">
  <form btsBody class="select-time">
    <mat-card class="demo-inline-calendar-card w100 calendar">
      <mat-calendar #calendar
      [(selected)]="selectedDateRange"
      (selectedChange)="rangeChanged($event)">
      </mat-calendar>
    </mat-card>
  </form>
</app-bottom-sheet-common-layout>
