import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateRangeComponent} from './date-range.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
@NgModule({
  declarations: [DateRangeComponent],
  imports: [
    MatDatepickerModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [DateRangeComponent],
})
export class DateRangeModule {}
