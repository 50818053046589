import {Component, Input} from '@angular/core';

@Component({
  selector: 'glom-slip-number-card',
  templateUrl: './slip-number-card.component.html',
  styleUrl: './slip-number-card.component.scss',
})
export class SlipNumberCardComponent {
  @Input() title = '';
  @Input() slipNumber: string | number = '';
  @Input() customStyle: string = '';
}
