import {Component, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'cms-upload',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @Input() severErrorDescription = '';
  @Input() severErrorURL = '';
  @Input() severSuccessDescription = '';
  @Output() emitFilesEvent = new EventEmitter();
  @Output() emitBackToUploadFile = new EventEmitter();
  @Output() closeMsgEvent = new EventEmitter();
  files: any[] = [];
  fileInvalidMsg = '';
  activeDrag = false;
  /*
   * Method when on Drag over file
   */
  onDragOver(event: any): void {
    event.preventDefault();
    if (!this.activeDrag) {
      this.activeDrag = true;
    }
  }

  /*
   * Method when onDrag leave file
   */
  onDragLeave(event: any): void {
    event.preventDefault();
    if (this.activeDrag) {
      this.activeDrag = false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any) {
    this.prepareFilesList(files.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<File>) {
    this.closeMsg();
    const newFiles = [files[files.length - 1]];
    if (this.activeDrag) {
      this.activeDrag = false;
    }
    if (newFiles.length && newFiles[0]) {
      if (
        newFiles[0].size > 8388608 ||
        newFiles[0].type !== 'text/csv' ||
        files.length > 1
      ) {
        this.fileInvalidMsg =
          '取り込みができませんでした。対象ファイルが、10MB以下、CSV形式、1ファイルのみであることをご確認ください。';
      } else {
        this.fileInvalidMsg = '';
        for (const file of newFiles) {
          // item.progress = 0;
          this.files = [];
          this.files.push(file);
        }
      }
    }
    // this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Uploads file
   */
  uploadFile() {
    if (!this.fileInvalidMsg) {
    }
    this.emitFilesEvent.emit(this.files);
  }
  /**
   * Resets files
   */
  resetFiles() {
    this.files = [];
    this.severErrorDescription = '';
    this.fileInvalidMsg = '';
  }

  /**
   * back to the upload file screen
   */
  handleBacktoUploadFile(): void {
    this.emitBackToUploadFile.emit();
  }

  /**
   * emit event closeMsg when user click to close icon
   */
  closeMsg(): void {
    this.closeMsgEvent.emit();
  }

  handleGetfileErr() {
    const fileUrl = this.severErrorURL;
    window.location.href = fileUrl;
  }
}
