export const CMS_CONST = {
  dateFormat: 'yyyy/MM/dd',
  dateTimeFormat: 'yyyy/MM/dd HH:mm',
  dateTimeFormatExport: 'yyyyMMddHHmmss',
  haflSizeRegex: /^[a-zA-Z0-9`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/,
  numberRegex: /^[0-9]+$/,
};

export const REGEX_IMAGE_TYPE =
  /\.(jpg|jpeg|png|tiff|gif|bmp|heic|heif|JPG|JPEG|PNG|TIFF|GIF|BMP|HEIC|HEIF)$/;

export const IGNORE_ENDPOINT_LOADING = ['/pm/pim/admin/parcel/export-csv'];

export const CMS_ROUTER_CONSTANT = {
  voucherIssuerList: '/main/user-management/voucher-issuer',
  voucherIssuerRegister: '/main/user-management/voucher-issuer/register',
  voucherIssuerEdit: '/main/user-management/voucher-issuer/edit',
  voucherIssuerDetail: '/main/user-management/voucher-issuer/detail',
  companyList: '/main/user-management/company',
  companyRegister: '/main/user-management/company/register',
  companyEdit: '/main/user-management/company/edit',
  companyDetail: '/main/user-management/company/detail',
  emailSettingsList: '/main/user-management/email-settings',
  emailSettingsRegister: '/main/user-management/email-settings/register',
  emailSettingsEdit: '/main/user-management/email-settings/edit',
  emailSettingsDetail: '/main/user-management/email-settings/detail',
};
