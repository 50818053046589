import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {API} from '@glom-cms/constants/api.const';
import {MainService} from '@glom-cms/main/main.service';
import {
  IReqAddPackageHistory,
  IReqExportCSV,
  IReqExportCSVStatus,
  IReqPackages,
  IReqUpdatePackageMemo,
  IResPackages,
  ListImage,
} from '@glom-cms/main/package-management/shared/package.models';
import {CommonService} from '@glom-cms/services/common.service';
import {
  IGNORE_LOADING_KEY,
  PARCEL_IMAGE_TYPE,
} from '@glom-common/consts/common.const';
import * as moment from 'moment';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PackageApiService {
  constructor(
    private readonly commonService: CommonService,
    private readonly httpClient: HttpClient,
    private readonly mainService: MainService
  ) {}

  searchPackages(body: IReqPackages): Observable<IResPackages> {
    const api = this.commonService.buildAPIUrl(API.package.searchParcel);
    return this.httpClient
      .post(api, body)
      .pipe(map((res: any) => res.data)) as Observable<IResPackages>;
  }

  getPackageDetail(packageId: string) {
    const api = this.commonService.buildAPIUrl(API.package.getPackageDetail, {
      packageId,
    });
    return this.httpClient.get(api) as Observable<any>;
  }

  /**
   * Constructs a `POST` request that upload file the body as a
   * JSON object and returns the response body as a JSON object.
   *
   * @param file The content to replace with.
   *
   * @return An `Observable` of the response, with the response body as a JSON object.
   */
  importPackageCsv(file: File): Observable<any> {
    const api = this.commonService.buildAPIUrl(API.package.importPackage);
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dataFile = new FormData();
    dataFile.append('file', file);
    dataFile.append('timeZone', moment().tz(currentTimeZone).format('Z'));
    return this.httpClient.post(api, dataFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  exportCsv(body: IReqExportCSV): Observable<any> {
    const api = this.commonService.buildAPIUrl(API.package.exportCsv);
    return this.httpClient.post(api, body, {
      headers: {
        [IGNORE_LOADING_KEY]: 'true',
      },
    }) as Observable<any>;
  }

  exportCsvStatus(body: IReqExportCSVStatus): Observable<any> {
    const api = this.commonService.buildAPIUrl(API.package.exportCSVStatus);
    return this.httpClient.post(api, body, {
      headers: {
        [IGNORE_LOADING_KEY]: 'true',
      },
    }) as Observable<any>;
  }

  packageHistory(packageId: string) {
    const api = this.commonService.buildAPIUrl(API.package.packageHistory, {
      packageId,
    });
    return this.httpClient.get(api) as Observable<any>;
  }

  addPackageHistory(
    body: IReqAddPackageHistory,
    packageId: number
  ): Observable<IResPackages> {
    const api = this.commonService.buildAPIUrl(API.package.packageHistory, {
      packageId,
    });
    return this.httpClient
      .post(api, body)
      .pipe(map((res: any) => res.data)) as Observable<IResPackages>;
  }

  searchShipper(shipperCode: string) {
    const api = this.commonService.buildAPIUrl(
      API.UserManagement.searchShipper,
      {
        userCode: shipperCode,
      }
    );
    return this.httpClient.get(api, {
      headers: {
        [IGNORE_LOADING_KEY]: 'true',
      },
    }) as Observable<any>;
  }

  updatePackageNote(
    body: IReqUpdatePackageMemo,
    packageId: number
  ): Observable<IResPackages> {
    const api = this.commonService.buildAPIUrl(API.package.updateMemo, {
      packageId,
    });
    return this.httpClient
      .post(api, body)
      .pipe(map((res: any) => res.data)) as Observable<IResPackages>;
  }

  getPresignedUrl(packageId: number, files: any[], updateAt: string) {
    const body: ListImage = {
      imageList: this.getImageList(files),
      updateAt,
    };
    const api = this.commonService.buildAPIUrl(API.package.getPresignedUrl, {
      packageId,
    });
    return this.httpClient.post(api, body) as Observable<any>;
  }

  updatePackageImage(
    packageId: number,
    files: any[],
    parcelImages: any[],
    updateAt: string
  ) {
    const body = {
      imageList: this.getImageList(files),
      parcelImages: parcelImages ?? [],
      updateAt,
    };
    const api = this.commonService.buildAPIUrl(API.package.updatePackageImage, {
      packageId,
    });
    return this.httpClient.post(api, body) as Observable<any>;
  }

  getImageList = (files: any[]) => {
    return (
      files?.map((file, index) => ({
        fileName: file.name,
        imageType: PARCEL_IMAGE_TYPE.Photo,
        rank: 1 + index,
      })) ?? []
    );
  };
}
