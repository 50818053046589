import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'noValue',
  standalone: true,
})
class NoValuePipe implements PipeTransform {
  constructor() {
    // Todo function
  }
  transform(value: any, isMobile?: boolean): any {
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string' && value) {
      return value.trim() ? value : isMobile ? 'データなし' : '---';
    }
    return value ? value : isMobile ? 'データなし' : '---';
  }
}

export default NoValuePipe;
