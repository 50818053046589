import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {CMS_CONST} from '@glom-cms/constants/cms.const';
import NoValuePipe from './no-value.pipe';
@Pipe({
  name: 'timeFromTo',
  standalone: true,
})
class TimeFromToPipe implements PipeTransform {
  constructor(
    private readonly datePipe: DatePipe,
    private readonly noValue: NoValuePipe
  ) {}
  transform(datefrom: any, dateto: any, timeFrom: any, timeTo: any): any {
    const transformTime = (time: string) => {
      const date = new Date();
      if (time) {
        const stringTime = `${this.datePipe.transform(
          date,
          'yyyy-MM-dd'
        )}T${time}:00+00:00`;
        return this.datePipe.transform(stringTime, 'HH:mm');
      }
      return time;
    };
    const timeFromText =
      timeFrom && datefrom
        ? this.noValue.transform(this.datePipe.transform(datefrom, 'HH:mm'))
        : timeFrom;
    const timeToText =
      timeTo && dateto
        ? this.noValue.transform(this.datePipe.transform(dateto, 'HH:mm'))
        : timeTo;
    if (timeFromText === timeToText) {
      return timeFromText || '指定なし';
    } else {
      if (!timeFromText || !timeToText) {
        return `${timeFromText || '00:00'} ~ ${timeToText || '23:59'}`;
      } else return `${timeFromText} ~ ${timeToText}`;
    }
  }
}

export default TimeFromToPipe;
