// src/app/services/tiff-conversion.service.ts
import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {from, Observable, of, switchMap} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

declare const Tiff: any;

@Injectable({
  providedIn: 'root',
})
export class TiffConversionService {
  private renderer: Renderer2;
  jpgBase64Data: string = '';

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.loadTiffScript();
  }

  convertTiffToJpg(base64Data: string): string {
    if (typeof Tiff === 'undefined') {
      console.error('Tiff is not loaded yet.');
      return '';
    }

    // Remove the base64 header
    base64Data = base64Data.replace(/^data:image\/[a-z]+;base64,/, '');

    // Decode the base64 string to binary data
    const binaryData = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));

    // Create a new Tiff object with the binary data
    const tiff = new Tiff({buffer: binaryData});

    // Convert the TIFF to a canvas
    const canvas = tiff.toCanvas();

    // Convert the canvas to a JPG base64 string
    const jpgBase64Data = canvas
      .toDataURL('image/jpeg')
      .replace(/^data:image\/jpeg;base64,/, '');

    // Return the final base64 data URI
    return 'data:image/jpeg;base64,' + jpgBase64Data;
  }

  convertAndDisplayTiff(s3Url: string): Observable<string> {
    return this.httpClient
      .get(s3Url, {
        responseType: 'blob',
        headers: {
          Authorization: '', // Add your actual authorization token if needed
          'Content-Type': 'image/tiff',
        },
      })
      .pipe(
        switchMap(blob => {
          return from(
            new Promise<string>((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const base64Data = e.target.result;
                const jpgBase64Data = this.convertTiffToJpg(base64Data);
                resolve(jpgBase64Data);
              };
              reader.onerror = error => {
                reject(error);
              };
              reader.readAsDataURL(blob);
            })
          );
        }),
        catchError(error => {
          console.error('Error fetching TIFF file', error);
          return of(''); // Use of instead of from to return an Observable
        })
      );
  }

  convertAndDisplayImage(s3Url: string): Observable<string> {
    if (s3Url) {
      return this.convertAndDisplayTiff(s3Url).pipe(
        tap(jpgBase64Data => {
          this.jpgBase64Data = jpgBase64Data;
        })
      );
    }
    return of(this.jpgBase64Data);
  }

  private loadTiffScript(): void {
    const script = this.renderer.createElement('script');
    script.src = 'assets/js/tiff/tiff.min.js';
    script.onload = () => {
      // console.log('Tiff script loaded');
    };
    script.onerror = () => {
      console.error('Error loading Tiff script');
    };
    this.renderer.appendChild(this.document.body, script);
  }
}
