<div class="package-card">
  <div class="p-8 package-content">
    <div class="d-flex package-item">
      <div class="package-item-label">伝票番号</div>
      <div
        class="package-item-content"
        style="font-size: 22px; line-height: 35px">
        {{ packageData.packageId | truncate : 50 }}
      </div>
    </div>
    <div class="d-flex package-item">
      <div class="package-item-label">伝票発行会社</div>
      <div class="package-item-content">
        {{ packageData.receiptCompany | truncate : 50 }}
      </div>
    </div>
  </div>
</div>
