<div class="d-flex date-range" [formGroup]="dateRangeForm">
  <mat-form-field
    floatLabel="always"
    appearance="outline"
    class="w100 form-feild-custom">
    <input
      (blur)="onBlurEvent($event)"
      (dateChange)="onChange()"
      [min]="minDateTo"
      id=""
      matInput
      [matDatepicker]="picker31"
      placeholder="{{dateRangePlaceholder.from}}"
      formControlName="from" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="picker31"></mat-datepicker-toggle>
    <mat-datepicker #picker31></mat-datepicker>
    <mat-error>
      <ng-content select="[errorDateFrom]"></ng-content>
    </mat-error>
  </mat-form-field>

  <div class="d-flex align-items-center date-range__line">
    <p>{{ ' - ' }}</p>
  </div>

  <mat-form-field
    floatLabel="always"
    appearance="outline"
    class="w100 form-feild-custom">
    <input
      (blur)="onBlurEvent($event)"
      (dateChange)="onChange()"
      id=""
      matInput
      [min]="minDateFrom"
      [matDatepicker]="picker32"
      placeholder="{{dateRangePlaceholder.to}}"
      formControlName="to" />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="picker32"></mat-datepicker-toggle>
    <mat-datepicker #picker32></mat-datepicker>
    <mat-error>
      <ng-content select="[errorDateTo]"></ng-content>
    </mat-error>
  </mat-form-field>
</div>
