import {Injectable} from '@angular/core';
import {
  HttpBackend,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {BehaviorSubject, finalize, Observable, throwError, timer} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CommonService} from '@glom-cms/services/common.service';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {
  ACCESS_TOKEN,
  IGNORE_LOADING_KEY,
  REFRESH_TOKEN,
} from '@glom-common/consts/common.const';
import {Router} from '@angular/router';
import {LoadingService, ToastMessageService} from '@glom-fe/shared-ui';
import {API, EXCEPT_AUTHORIZATION_URL} from '@glom-cms/constants/api.const';
import {IGNORE_ENDPOINT_LOADING} from '@glom-cms/constants/cms.const';
import {MatDialog} from '@angular/material/dialog';
import {AuthApiService} from '@glom-cms/auth/auth.api.service';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  private readonly requests: Array<HttpRequest<any>> = [];
  private isRefreshing = false;
  private isOpeningErrPopup = false;
  private readonly tokenSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  constructor(
    private readonly commonService: CommonService,
    private readonly httpBackend: HttpBackend,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly toastMessageService: ToastMessageService,
    private readonly translateService: TranslateService,
    private readonly dialogRef: MatDialog,
    private readonly authApiService: AuthApiService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken: any = localStorage.getItem(REFRESH_TOKEN);
    const isIgnoreLoading: boolean =
      IGNORE_ENDPOINT_LOADING.some(endpoint =>
        request.url.includes(endpoint)
      ) || JSON.parse(request.headers.get(IGNORE_LOADING_KEY) || 'false');

    if (this.loadingService.isAutoLoadingDisplay && !isIgnoreLoading) {
      if (request.url.includes(API.package.importPackage)) {
        this.loadingService.handleTextLoadingDisplay('読み込み中');
      }
      this.loadingService.handleLoadingDisplay(true);
    }
    // Build http request header
    let headers = new HttpHeaders();
    if (!request.headers.get('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }
    headers = this.commonService.setHeaderDatas(headers);

    let isUnAuthorRequest = false;
    EXCEPT_AUTHORIZATION_URL.forEach(url => {
      if (request.url.includes(url)) {
        isUnAuthorRequest = true;
      }
    });

    if (
      authToken &&
      !request.headers.has('Authorization') &&
      !isUnAuthorRequest
    ) {
      headers = headers.append('Authorization', 'Bearer ' + authToken);
    }

    if (!this.commonService.isOnline()) {
      const error = new HttpErrorResponse({
        error: 'Internet connectivity error',
        status: 0,
      });
      this.showToastFailure(error);
      throwError(() => error);
    }

    const cloneReq = request.clone({headers});
    const authReq = cloneReq;
    this.requests.push(authReq);
    return next.handle(cloneReq).pipe(
      catchError(error => {
        if ((error as any).status === 401) {
          const index = this.requests.indexOf(authReq);
          if (index >= 0) {
            this.requests.splice(index, 1);
          }
          if (request.url.includes(API.refreshToken)) {
            this.isRefreshing = false;
            sessionStorage.clear();
            localStorage.clear();
            this.commonService.userInfo$.next(null);
            this.dialogRef.closeAll();
            this.router.navigate(['/sign-in']);
            return throwError(() => error);
          }
          return this.handle401Error(cloneReq, next, refreshToken);
        }
        // if (err.status === 403 && !err?.error?.errorCode) {
        //   return this.handle403Error(cloneReq, next);
        // }
        this.showToastFailure(error);
        return throwError(() => error);
      }),
      finalize(() => {
        const index = this.requests.indexOf(authReq);
        if (index >= 0) {
          this.requests.splice(index, 1);
        }
        timer(200).subscribe(() => {
          if (
            this.requests.length === 0 &&
            this.loadingService.isAutoLoadingDisplay
          ) {
            this.loadingService.handleLoadingDisplay(false);
            this.loadingService.handleTextLoadingDisplay('');
          }
        });
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    refreshToken: string
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.tokenSubject.next(null);

      // Call refresh token API
      return this.authApiService.refreshToken(refreshToken).pipe(
        switchMap((newTokens: any) => {
          this.isRefreshing = false;
          this.tokenSubject.next(newTokens?.data.accessToken);

          localStorage.setItem(ACCESS_TOKEN, newTokens?.data.accessToken);
          localStorage.setItem(REFRESH_TOKEN, newTokens?.data.refreshToken);

          // Retry the original request with the new access token
          return next.handle(
            this.addNewTokenHeader(request, newTokens?.data.accessToken)
          );
        }),
        catchError(err => {
          this.isRefreshing = false;
          sessionStorage.clear();
          localStorage.clear();
          this.commonService.userInfo$.next(null);
          this.dialogRef.closeAll();
          this.router.navigate(['/sign-in']);
          return throwError(() => err);
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        switchMap(token => {
          return next.handle(this.addNewTokenHeader(request, token));
        })
      );
    }
  }

  private showToastFailure(error: any | HttpErrorResponse): void {
    if (
      (error?.error?.errorCode &&
        ErrorCodeIgnored.includes(error?.error?.errorCode)) ||
      (error?.error?.data?.errorCode &&
        ErrorCodeIgnored.includes(error?.error?.data?.errorCode))
    )
      return;
    if (error?.error?.errorCode) {
      this.toastMessageService.notifyErrorMessage(
        this.translateService.instant(error?.error?.errorCode)
      );
    } else {
      this.toastMessageService.notifyErrorMessage(
        this.translateService.instant('CM_TOA_0007')
      );
    }
  }

  private handleInOpenPopup(dialogRef: Observable<any>) {
    this.isOpeningErrPopup = true;
    dialogRef.subscribe(res => {
      this.isOpeningErrPopup = false;
    });
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    // Todo function
  }

  private addNewTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });
  }
}

const ErrorCodeIgnored = [
  'CM_POP_0004',
  'CM_BM_0025',
  'CM_BM_0039',
  'CM_BM_0037',
  'CM_BM_0038',
];
