// scroll.service.ts
import {Injectable} from '@angular/core';
import {Observable, fromEvent} from 'rxjs';
import {map, filter, throttleTime} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollPosition = 0;

  constructor() {
    // TODO something
  }

  getScroll(): Observable<Event> {
    return fromEvent(window, 'scroll').pipe(
      filter(() => this.isScrollNearBottom()),
      map(event => event)
    );
  }
  private isScrollNearBottom(): boolean {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.scrollY;

    return windowBottom >= docHeight;
  }

  setScrollPosition(position: number) {
    this.scrollPosition = position;
  }

  getScrollPosition(): number {
    return this.scrollPosition;
  }
}
