<div class="package-card" (click)="handleClick(packageData)">
  <div
    *ngIf="packageData.hasHeader"
    class="package-header p-8 d-flex justify-content-between align-items-center">
    <div>{{ packageData.packageId }}</div>
    <img src="assets/icons/ic_arrow-right.svg" />
  </div>
  <div class="p-8 package-content">
    @if (!(packageData.receiptMemo || packageData.imageUrls)) {
    <div class="d-flex package-item">
      <img src="assets/icons/ic_user-package.svg" />
      @if (!packageData.hasfullText) {
      <div>
        {{ packageData.recipientName | noValue : true | truncate : 50 }}
      </div>
      } @else {
      <div>{{ packageData.recipientName | noValue : true }}</div>
      }
    </div>
    @if (!packageData.detailMode) {
    <div class="d-flex package-item">
      <img src="assets/icons/ic_office.svg" />
      @if (!packageData.hasfullText) {
      <div>
        {{
          packageData.recipientAddressPref +
            packageData.recipientAddressCity +
            packageData.recipientAddressDetail
            | truncate : 50
            | noValue : true
        }}
      </div>
      } @else {
      <div>
        {{
          packageData.recipientAddressPref +
            packageData.recipientAddressCity +
            packageData.recipientAddressDetail | noValue : true
        }}
      </div>
      }
    </div>
    } @else {
    <div class="d-flex package-item">
      <img src="assets/icons/ic_office.svg" />
      <a>{{
        packageData.recipientAddressPref +
          packageData.recipientAddressCity +
          packageData.recipientAddressDetail
      }}</a>
    </div>
    } @if (packageData.recipientTele) {
    <div class="d-flex package-item">
      <img src="assets/icons/ic_telephone.svg" />
      <a href="tel:{{ packageData.recipientTele }}">{{
        packageData.recipientTele
      }}</a>
    </div>
    }
    <div class="d-flex package-item" *ngIf="isOnePackage">
      <img src="assets/icons/ic_calendar.svg" />
      <div>
        {{
          packageData.deliverySpecifyDateFrom
            | dateFromTo : packageData.deliverySpecifyDateTo
        }}
      </div>
    </div>
    <div class="d-flex package-item" *ngIf="isOnePackage">
      <img src="assets/icons/ic_time.svg" />
      <div>
        {{
          packageData.deliverySpecifyDateFrom
            | timeFromTo
              : packageData.deliverySpecifyDateTo
              : packageData.deliverySpecifyTimeFrom
              : packageData.deliverySpecifyTimeTo
        }}
      </div>
    </div>
    @if (packageData.receiptQuantity || packageData.receiptWeight){
    <div class="d-flex">
      <div class="d-flex package-item">
        <img src="assets/icons/ic_package-box.svg" />
        <div>{{ packageData.receiptQuantity }}個</div>
      </div>
      <div class="d-flex package-item">
        <img src="assets/icons/ic_weight-scale.svg" />
        <div>{{ packageData.receiptWeight }}kg</div>
      </div>
    </div>
    } } @if (packageData.receiptMemo) {
    <div class="package-item-vertical">
      <div class="d-flex package-item">
        <img src="assets/icons/ic_package-memo.svg" />
        <div style="color: #5a6472">荷物メモ</div>
      </div>
      <div>{{ packageData.receiptMemo }}</div>
    </div>
    } @if (packageData.imageUrls) {
    <div class="package-item-vertical">
      <div class="d-flex package-item">
        <img src="assets/icons/ic_package-image.svg" />
        <div style="color: #5a6472">画像</div>
      </div>
      @for (item of packageData.imageUrls; track $index) {
      <img src="{{ item }}" />
      }
    </div>
    }
  </div>
</div>
