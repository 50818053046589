import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[glomFeClickOutSide]',
  standalone: true,
})
export class ClickOutside {
  constructor(private readonly ele: ElementRef) {}
  @Output() clickOutSideEvent = new EventEmitter();
  @HostListener('document:click', ['$event.target'])
  handleClickOutSide(target: any) {
    const eleClick = this.ele.nativeElement.contains(target);
    if (!eleClick) {
      this.clickOutSideEvent.emit();
    }
  }
}
