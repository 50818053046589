import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DndDirective} from './dnd.directive';
import {ProgressComponent} from './progress/progress.component';
import {UploadFileComponent} from './upload-file.component';
import {MatButtonModule} from '@angular/material/button';
@NgModule({
  declarations: [ProgressComponent, UploadFileComponent],
  imports: [DndDirective, CommonModule, MatButtonModule],
  exports: [UploadFileComponent],
})
export class UploadFileModule {}
