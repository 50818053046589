<app-bottom-sheet-common-layout
  (positiveEvent)="onPositiveEvent()"
  (negativeEvent)="onNegativeEvent()"
  title="配達時間指定">
  <form btsBody class="select-time" [formGroup]="timePickerForm">
    <div class="content">
      <div class="label">
        <p>から</p>
        <p>まで</p>
      </div>
      <div class="time">
        <div class="start" #timeFrom>
          <mat-button-toggle-group
            formControlName="timeFrom"
            [hideSingleSelectionIndicator]="true">
            <mat-button-toggle
              [id]="timeOption.id"
              *ngFor="let timeOption of timeFromOptions"
              [value]="timeOption.value">
              <p>{{ timeOption.value }}</p>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="dash">-</div>
        <div class="end" #timeTo>
          <mat-button-toggle-group
            formControlName="timeTo"
            [hideSingleSelectionIndicator]="true">
            <mat-button-toggle
              [id]="timeOption.id"
              *ngFor="let timeOption of timeToOptions"
              [value]="timeOption.value">
              <p>{{ timeOption.value }}</p>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>
  </form>
</app-bottom-sheet-common-layout>
