import {Directive, HostListener, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[numberAfterDecimalPoint]',
  standalone: true,
})
export class NumberAfterDecimalPointDirective implements OnInit{
  @Input('numberAfterDecimalPoint') numberAfterDecimalPoint!: number;
  private regex!: RegExp;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.regex = new RegExp(`^\\d+(\\.\\d{0,${this.numberAfterDecimalPoint}})?$`);
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const current: string = this.el.nativeElement.value;
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'Tab' ||
      event.key === 'Escape' ||
      event.key === 'Enter' ||
      (event.key === 'a' && (event.ctrlKey || event.metaKey)) || // Ctrl+A
      (event.key === 'c' && (event.ctrlKey || event.metaKey)) || // Ctrl+C
      (event.key === 'v' && (event.ctrlKey || event.metaKey)) || // Ctrl+V
      (event.key === 'x' && (event.ctrlKey || event.metaKey)) || // Ctrl+X
      (event.key === 'ArrowLeft') || // Arrow Left
      (event.key === 'ArrowRight') // Arrow Right
    ) {
      return;  // let it happen, don't do anything
    }
    // Check if the current value already has the maximum allowed decimal places
    if (current.includes('.') && current.split('.')[1].length >= this.numberAfterDecimalPoint) {
      event.preventDefault();
      return;
    }
  }
}
