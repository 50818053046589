import {APP_INITIALIZER, InjectionToken, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {CommonInterceptor} from '@glom-cms/services/common.interceptor';
import {MatIconRegistry} from '@angular/material/icon';
import {LoadingModule, ToastMessageModule} from '@glom-fe/shared-ui';
import {AccountInfoModule} from '@glom-cms/components/account-info/account-info.module';
import {AuthApiService} from '@glom-cms/auth/auth.api.service';

export function createTranslateLoader(httpBE: HttpBackend) {
  return new TranslateHttpLoader(
    new HttpClient(httpBE),
    './assets/i18n/',
    '.json'
  );
}
export const APP_CONFIG: InjectionToken<any> = new InjectionToken<any>(
  'Application Configuration'
);

export function appInitializerFactory(translate: TranslateService) {
  return () => translate.use('jp').toPromise();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoadingModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    AccountInfoModule,
    ToastMessageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
      defaultLanguage: 'jp',
    }),
  ],
  providers: [
    MatIconRegistry,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CommonInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    AuthApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
