<div class="d-flex date-range" [formGroup]="timeRangeForm" style="position: relative" #rangeTimeElement>
  <div
    class="wrap-form-field-range-time">
    <mat-form-field
      floatLabel="always"
      hideRequiredMarker="false"
      appearance="outline"
      class="form-field-time">
      <input
        matInput
        class=""
        type="time"
        formControlName="from"
        (change)="onChangeSendingTime()"
        (blur)="onUpdateSendingTime()"
        (keyup)="onUpdateSendingTime()" />
    </mat-form-field>
    <span>-</span>
    <mat-form-field
      floatLabel="always"
      hideRequiredMarker="false"
      appearance="outline"
      class="form-field-time">
      <input
        matInput
        class=""
        type="time"
        formControlName="to"
        (change)="onChangeSendingTime()"
        (blur)="onUpdateSendingTime()"
        (keyup)="onUpdateSendingTime()" />
    </mat-form-field>
  </div>
  <!-- <mat-error
    style="position: absolute; width: max-content; bottom: 0;"
    *ngIf="isShowErrorRangeTime"
    >{{ timeRangeForm?.controls?.['timeHidden']?.errors?.['validTime'] }}</mat-error
  > -->
</div>
