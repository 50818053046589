import {AbstractControl, ValidationErrors} from '@angular/forms';

/**
 * @description Handle check full-size validator
 * @param control
 * @returns
 */
export function fullWidthValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value || '';
  // Regular expression to match full-width characters
  const fullWidthPattern = /[^\u0020-\u007E\uFF61-\uFF9F\uFFA0-\uFFEF]/;
  return fullWidthPattern.test(value) ? {commonErr: true} : null;
}

export const checkAllNull = (data: unknown[]): boolean => {
  return data.every((value: unknown) => {
    if (value === null || value === '' || value === undefined) {
      return true;
    }
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return checkAllNull(value);
      }
      return Object.values(value).every(
        prop => prop === null || prop === '' || prop === undefined
      );
    }
    return false;
  });
};

export function setHoursMidNightToDate(date: string): string {
  let result = '';
  result = date
    ? new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString()
    : date;
  return result;
}

export function setToLastHoursOfDay(date: string): string {
  let result = '';
  result = date
    ? new Date(new Date(date).setHours(23, 59, 59, 0)).toISOString()
    : date;
  return result;
}
