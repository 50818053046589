import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CommonService} from '@glom-cms/services/common.service';
import {Observable} from 'rxjs';
import {API} from '@glom-cms/constants/api.const';
import {IGNORE_LOADING_KEY} from '@glom-common/consts/common.const';
@Injectable()
export class AuthApiService {
  constructor(
    private readonly commonService: CommonService,
    private readonly httpClient: HttpClient
  ) {}
  login(body: any): Observable<any> {
    const api = this.commonService.buildAPIUrl(API.signIn);
    return this.httpClient.post(api, body) as Observable<any>;
  }

  logout(): Observable<any> {
    const api = this.commonService.buildAPIUrl(API.signOut);
    return this.httpClient.post(api, {}) as Observable<any>;
  }

  refreshToken(refreshToken: string): Observable<any> {
    const api = this.commonService.buildAPIUrl(API.refreshToken);
    return this.httpClient.post(
      api,
      {refreshToken},
      {
        headers: {
          [IGNORE_LOADING_KEY]: 'true',
        },
      }
    ) as Observable<any>;
  }
}
