import {Injectable, OnInit, OnDestroy} from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarDismiss,
} from '@angular/material/snack-bar';
import {
  fromEvent,
  debounceTime,
  Subscription,
  BehaviorSubject,
  Observable,
} from 'rxjs';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {ToastType} from './toast-message.const';

@Injectable()
export class ToastMessageService implements OnDestroy {
  isMobile = false;
  subscription$: Subscription | null = null;
  constructor(private snackBar: MatSnackBar) {
    this.subscription$ = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.isMobile = this.checkIsMobile();
      });
  }

  checkIsMobile(): boolean {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      userAgent
    );
  }

  notifyMessage(message: string): Observable<MatSnackBarDismiss> {
    const resultRef = this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message,
        type: ToastType.Success,
      },
      panelClass: ['mdc-snackbar__surface--success'],
      ...this.getMatSnackBarConfig(),
    });
    return resultRef.afterDismissed();
  }

  notifyErrorMessage(errorMessage: string): Observable<MatSnackBarDismiss> {
    const resultRef = this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: errorMessage,
        type: ToastType.Error,
      },
      panelClass: ['mdc-snackbar__surface--error'],
      ...this.getMatSnackBarConfig(),
    });
    return resultRef.afterDismissed();
  }

  notifyInformMessage(informMessage: string): Observable<MatSnackBarDismiss> {
    const resultRef = this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: informMessage,
        type: ToastType.InForm,
      },
      panelClass: ['mdc-snackbar__surface--inform'],
      ...this.getMatSnackBarConfig(),
    });
    return resultRef.afterDismissed();
  }

  getMatSnackBarConfig(): MatSnackBarConfig {
    return {
      duration: 3000,
      horizontalPosition:
        !this.isMobile && !this.checkIsMobile() ? 'right' : 'center',
      verticalPosition:
        !this.isMobile && !this.checkIsMobile() ? 'top' : 'bottom',
    };
  }

  notifyEmpty(message: string = ''): Observable<MatSnackBarDismiss> {
    const resultRef = this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message,
        type: null,
      },
      panelClass: ['mdc-snackbar__surface--success'],
      ...this.getMatSnackBarConfig(),
      duration: 1,
    });
    return resultRef.afterDismissed();
  }

  ngOnDestroy(): void {
    this.subscription$?.unsubscribe();
  }
}
