<div class="bottom-sheet-layout">
  <div class="luggage-header">
    <img
      alt=""
      height="68"
      src="assets/icons/ic_luggage-infor.png"
      width="100%" />
    <div class="wrap-header-text">
      <div class="header-text">{{ title }}</div>
    </div>
  </div>
  <div class="bts-body">
    <ng-content #btsBody></ng-content>
  </div>
  <div class="bts-footer">
    <button
      (click)="onNegativeButton()"
      type="button"
      style="width: 100%"
      class="btn-cancel"
      mat-flat-button
      color="accent">
      キャンセル
    </button>
    <button
      (click)="onPositiveButton()"
      [disabled]="isDisabledPositiveBtn"
      type="button"
      style="width: 100%"
      class="btn-cancel"
      mat-flat-button
      color="primary">
      登録
    </button>
  </div>
</div>
