import {Pipe, PipeTransform} from '@angular/core';
import {STATUS} from '../constants';
@Pipe({
  name: 'packageStatus',
  standalone: true,
})
export class PackageStatusPipe implements PipeTransform {
  constructor() {}

  transform(statusId: string): string {
    return (
      STATUS.find(status => status.key.toLowerCase() === statusId)?.name || ''
    );
  }
}
