import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ACCESS_TOKEN, ENVIRONMENTS} from '@glom-common/consts/common.const';
import {NavigationStart, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {LoadingService} from '@glom-fe/shared-ui';
import {Subject, distinctUntilChanged, filter, takeUntil} from 'rxjs';
import {environments as ENV} from '@glom-cms/env/environment';

@Component({
  selector: 'glom-fe-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'glom-cms';
  isShowLoading: boolean = true;
  // textLoading = '読み込み中';
  textLoading: string = '';
  unsubscribe$ = new Subject();
  constructor(
    private readonly router: Router,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {
    this.loadingService.textLoadingDisplay$
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(val => {
        this.textLoading = val;
        this.cdr.detectChanges();
      });
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token && !location.href.includes('/guest')) {
      this.router.navigate(['sign-in']);
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        if ((event as NavigationStart).url === '/main') {
          this.router.navigate(['/main/packages']);
        }
        if ((event as NavigationStart).url === '/') {
          this.router.navigate(['/main/packages']);
        }
      });
    // this.matIconRegistry.addSvgIcon('ic-select', this.domSanitizer.bypassSecurityTrustResourceUrl(
    //   '/assets/icons/ic_vector.svg'
    // ))
  }

  ngOnInit(): void {
    if (ENV.BASE_URL === ENVIRONMENTS.STAGING) {
      document.body.classList.add('env-staging');
    }
    if (ENV.BASE_URL === ENVIRONMENTS.PRE) {
      document.body.classList.add('env-pre');
    }
  }

  ngAfterViewInit(): void {
    this.loadingService.isLoadingDisplay$
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(val => {
        // Promise.resolve().then(() => (this.isShowLoading = val));
        this.isShowLoading = val;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
  }
}
