import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LoadingService {
  private _isLoadingDisplay$ = new BehaviorSubject<boolean>(false);
  private _textLoading$ = new BehaviorSubject<string>('');
  private _isAutoLoadingDisplay = true;

  get isLoadingDisplay$() {
    return this._isLoadingDisplay$.asObservable();
  }
  get textLoadingDisplay$() {
    return this._textLoading$.asObservable();
  }

  get isAutoLoadingDisplay() {
    return this._isAutoLoadingDisplay;
  }

  handleLoadingDisplay(flag: boolean): void {
    this._isLoadingDisplay$.next(flag);
  }

  handleTextLoadingDisplay(text: string): void {
    this._textLoading$.next(text);
  }

  handleAutoLoadingDisplay(flag: boolean): void {
    this._isAutoLoadingDisplay = flag;
  }
}
