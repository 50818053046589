<div
  class="snack-bar"
  [class.snack-bar--success]="data.type === toastType.Success"
  *ngIf="data.type === toastType.Success">
  <div class="snack-bar__group-message">
    <img src="assets/icons/ic_checkmark-green.svg" alt="" srcset="" />
    <p class="group-message--message">{{ data.message }}</p>
  </div>
  <div class="snack-bar__img" (click)="snackBarRef.dismiss()">
    <img src="assets/icons/ic_close.svg" alt="" />
  </div>
</div>

<div
  class="snack-bar"
  [class.snack-bar--error]="data.type === toastType.Error"
  *ngIf="data.type === toastType.Error">
  <div class="snack-bar__group-message">
    <img src="assets/icons/ic_toast_error.svg" alt="" srcset="" />
    <p class="group-message--message">{{ data.message }}</p>
  </div>
  <div class="snack-bar__img" (click)="snackBarRef.dismiss()">
    <img src="assets/icons/ic_close.svg" alt="" />
  </div>
</div>

<div
  class="snack-bar"
  [class.snack-bar--inform]="data.type === toastType.InForm"
  *ngIf="data.type === toastType.InForm">
  <div class="snack-bar__group-message">
    <img src="assets/icons/ic_toast_inform.svg" alt="" srcset="" />
    <p class="group-message--message">{{ data.message }}</p>
  </div>
  <div class="snack-bar__img" (click)="snackBarRef.dismiss()">
    <img src="assets/icons/ic_close.svg" alt="" />
  </div>
</div>

<div *ngIf="data.type === null"></div>
