<div class="survey-note">
  <span class="d-flex note"
    ><img
      class="img_vector"
      src="assets/icons/ic_information.svg"
      alt=""
      width="24"
      height="24" />
    <p>{{ title }}</p></span
  >
  <!-- <p>
    お客様のお手元まで確実にお届けするため、お届け時のご要望と注意事項をお知らせください。
  </p> -->
</div>
<div class="address-memo" *ngIf="memoInfo?.name && memoInfo?.address">
  <div class="name">
    <h5>お名前</h5>
    <p>{{ memoInfo.name }}</p>
  </div>
  <div class="vector"></div>
  <div class="address">
    <h5>住所</h5>
    <p>{{ memoInfo.address }}</p>
  </div>
</div>
